import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Col, Container, Row} from 'reactstrap';
import ChooseExpert from '../ChooseExpert';
import FinproTekes from '../FinproTekes';
import {Link} from 'react-router-dom';


class MainPage extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		if (localStorage.getItem('lng') === 'en') {
		document.title = 'Expert Search is a service created by Business Finland'
		} else { document.title = 'Expert Search on Business Finlandin luoma palvelu' }
	}

	render() {
		return (
			<Container fluid className='text-center mx-0 px-0'>
				<div className='mainpage-header'>
					<div className='limit-width-and-center'>
						<Row className='limit-max-width-to-parent'>
							<Col className='px-4 pt-4 pb-4 text-uppercase'>
								<h1> <FormattedMessage id='mainPageHeader' /> </h1>
							</Col>
						</Row>
						<Row className='justify-content-center limit-max-width-to-parent'>
							<Col sm='8' className='px-5 pb-4'>
								<p>
									<FormattedMessage id='mainPageLeadText' />
								</p>
								<Link to='/search' className='btn btn-primary'>
									<FormattedMessage id='searchButton' />
								</Link>
							</Col>
						</Row>
					</div>
				</div>

				<ChooseExpert />
				<FinproTekes />
			</Container>
		)
	}

}

export default MainPage;
