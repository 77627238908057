import React from 'react';
import {Col, FormFeedback, FormGroup, FormText, Input, Label, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import validator from 'validator';
import FormattedMessageHOC from "../FormattedMessageHOC";

class SearchInputSection extends React.Component {
	constructor(props) {
		super(props);

		this.state = { valid: true };
	}

	checkInput(value, sectionName) {
		if (!value) {
			if (this.props.optional) {
				this.setState({ valid: true });
			} else {
				this.setState({ valid: false });
			}
			return;
		}

		if (sectionName === 'webpage' || sectionName === 'linkedin' || sectionName === 'facebook' || sectionName === 'link') {
			if (validator.isURL(value)) {
				this.setState({ valid: true });
			} else {
				this.setState({ valid: false });
			}
		} else if (sectionName === 'personEmail' || sectionName === 'email') {
			if (validator.isEmail(value)) {
				this.setState({ valid: true });
			} else {
				this.setState({ valid: false });
			}
		} else {
			this.setState({ valid: true });
		}
	}

	catchEnter(area, target) {
		if (target.charCode === 13 && area !== 'textarea') {
			target.preventDefault();
			this.props.handleSearch(target);
		}
	}

	render() {
		const {
			value,
			handleChange,
			sectionName,
			hiddenOnPublic,
			maxLength,
			placeholder,
			area,
			className,
			helperText
		} = this.props;

		var inputValid = this.state.valid;

		return (
			<FormGroup color={inputValid ? '' : 'warning'}>
				<Row className='limit-max-width-to-parent'>
					<Col sm={{ size: 'auto', offset: 0 }}>
						<Label for={sectionName} className='right-margin' size='sm' className='input-label'>
							<FormattedMessageHOC {...{id: sectionName}} />
						</Label>
					</Col>
					{hiddenOnPublic
						? <FormText color='muted' className='caps'> <FormattedMessage id='hiddenOnPublic' /> </FormText>
						: null}
				</Row>

				{helperText
					? <FormText><FormattedMessageHOC {...{id: helperText}} /></FormText>
					: null}

				<FormattedMessageHOC {...{id: placeholder}}>
					{message => <Input color='warning' className={className || ''} defaultValue={value || ''} type={area ? 'textarea' : 'text'} name={sectionName} id={sectionName} onInput={handleChange.bind(this, sectionName)} maxLength={maxLength} placeholder={message} state={inputValid ? '' : 'warning'} onBlur={this.checkInput.bind(this, value, sectionName)} onKeyUp={this.catchEnter.bind(this, area)} />}
				</FormattedMessageHOC>
				{inputValid
					? null
					: <FormFeedback> <FormattedMessage id='generalInputWarning' /> </FormFeedback>}

			</FormGroup>
		);
	}

}

export default SearchInputSection;