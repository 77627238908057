import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Col, Row} from 'reactstrap';
import pdf from '../../files/BF_ExpertSearchTermsofUse-Users040118.pdf';

class Footer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {isOpen: false}
	}

	toggle() {
    	this.setState({
      		isOpen: !this.state.isOpen
    	});
  	}

	render () {
		const { formatMessage } = this.props.intl;

		return (
			<Row className='footer text-center'>
				<Col lg={{ size: 5, push: 7 }} md={{ size: 5, push: 7 }} sm='12' xs='12'>
					<a target='_blank' href={pdf} className='text-white'>
						<FormattedMessage id='termsOfUse' />
					</a>
					 <span className='mx-1'>|</span>
					<a target='_blank' href={formatMessage({id:'accessibilityURL'})} className='text-white'>
						<FormattedMessage id='accessibility' />
					</a>
					<span className='mx-1'>|</span>
					<FormattedMessage id='footerEnd' />
				</Col>
			</Row>
		)
	}

}

export default injectIntl(Footer);
