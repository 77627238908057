import React from 'react';
import {Col, FormGroup, FormText, Label, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import Select from 'react-select';
import FormattedMessageHOC from "./FormattedMessageHOC";

class SelectSection extends React.Component {
	constructor(props) {
		super(props);
	}

	checkInput(value) {
		if (!value) {
			if (this.props.optional) {
				return true;
			} else {
				return false;
			}
		} else if (value == '') {
			return false;
		}
		return true;
	}

	checkOnSubmit(value) {
		var isValid = this.checkInput(value);

		if (this.props.submitClicked && isValid) {
			return true;
		} else if (this.props.submitClicked && !isValid) {
			return false;
		} else if (!this.props.submitClicked) {
			return true;
		}

	}

	render() {
		const {
			value,
			handleChange,
			sectionName,
			hiddenOnPublic,
			options,
			multiple,
			placeholder,
			helperText,
			className,
			limited
		} = this.props;

		// workaround for showing the selected values in react-select
		// find the key,value pair(s) that has the key of selected value (can be list, object, string, number...)
		let selectedValues = [];
		if (value && value instanceof Array) {
			value.forEach(val => {
				const found = options.find(option => val.value ? option.value === val.value : option.value === val);
				selectedValues.push(found);
			});
		} else {
			const found = options.find(option => value && value.value ? option.value === value.value : option.value === value);
			selectedValues.push(found);
		}

		var submitOK = this.checkOnSubmit(value);

		return (
			<FormGroup color={submitOK ? '' : 'danger'} className='text-start'>
				<Row className='limit-max-width-to-parent'>
					<Col sm={{size: 'auto', offset: 0}}>
						<Label for={sectionName} className='right-margin' size='sm' className='input-label'>
							<FormattedMessageHOC {...{id: sectionName}} /> {limited ? <FormattedMessage id='limited' /> : ''}
						</Label>
					</Col>
					{hiddenOnPublic
						? <FormText color='muted' className='caps'> <FormattedMessage id='hiddenOnPublic' /> </FormText>
						: null}
				</Row>

				{helperText
					? <FormText><FormattedMessageHOC {...{id: helperText}} /></FormText>
					: null}

				<FormattedMessageHOC {...{id: placeholder}}>
					{message => (
						<Select style={submitOK ? null : { borderColor: '#dc3545' }}
								value={selectedValues || ''}
								className={className}
								name={sectionName}
								id={sectionName}
								options={options}
								onChange={handleChange.bind(this, sectionName)}
								state={submitOK ? '' : 'warning'}
								isMulti={multiple}
								placeholder={message} />)}
				</FormattedMessageHOC>


				{submitOK
					? null
					: <div className="invalid-feedback-new">
						{(value && value.length > 0) ?
							<FormattedMessage id='generalInputWarning'/> :
							<FormattedMessage id='emptyInputWarning'/>
						}
					</div>
				}

			</FormGroup>
		);
	}

}

export default SelectSection;