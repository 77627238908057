import React from 'react';
import {Col, Row} from 'reactstrap';

import SelectSection from '../SelectSection.jsx';
import SearchInputSection from "./SearchInputSection";

class SearchFilters extends React.Component {
	constructor(props) {
		super(props);
	}

	render () {

		var {
			companyName,
			textSearch,
			serviceLanguage,
      location,
			expertName,
      industries,
			companySize,
			countries,
			services,
			onFilterChange,
			handleChange,
			companySizeOptions,
			parsedCountries,
			parsedServices,
			parsedIndustries,
			handleSearch,
		} = this.props;

		return (
			<div>
				<Row className='align-items-end ps-4 limit-max-width-to-parent'>
					<Col xl='lg' lg='4' md='6' sm='12' xs='12'>
						<SearchInputSection value={companyName} handleChange={handleChange.bind(this)} maxLength={50}
							sectionName='companyName' placeholder='targetCompanyNamePlaceholderText' optional handleSearch={handleSearch.bind(this)}/>
					</Col>
					<Col xl='lg' lg='4' md='6' sm='12' xs='12'>
            <SearchInputSection value={textSearch} handleChange={handleChange.bind(this)} maxLength={50}
                              sectionName='textSearch' placeholder='targetTextSearchPlaceholderText' optional handleSearch={handleSearch.bind(this)}/>
					</Col>
					<Col xl='lg' lg='4' md='6' sm='12' xs='12'>
            <SearchInputSection value={serviceLanguage} handleChange={handleChange.bind(this)} maxLength={50}
                              sectionName='serviceLanguage' placeholder='targetServiceLanguagePlaceholderText' optional handleSearch={handleSearch.bind(this)}/>
					</Col>
				</Row>
        <Row className='align-items-end ps-4 limit-max-width-to-parent'>
          <Col xl='lg' lg='4' md='6' sm='12' xs='12'>
            <SelectSection value={location} handleChange={onFilterChange.bind(this)} multiple
                           sectionName='location' options={parsedCountries} placeholder='locationOfExpertPlaceholderText' />
          </Col>
          <Col xl='lg' lg='4' md='6' sm='12' xs='12'>
            <SearchInputSection value={expertName} handleChange={handleChange.bind(this)} maxLength={50}
                              sectionName='expertName' placeholder='targetExpertNamePlaceholderText' optional handleSearch={handleSearch.bind(this)}/>
          </Col>
          <Col xl='lg' lg='4' md='6' sm='12' xs='12'>
            <SelectSection value={industries} handleChange={onFilterChange.bind(this)} multiple
                           sectionName='industries' options={parsedIndustries} placeholder='industryFocusPlaceholderText' />
          </Col>
				</Row>
        <Row className='align-items-end ps-4 limit-max-width-to-parent'>
          <Col xl='lg' lg='4' md='6' sm='12' xs='12'>
            <SelectSection value={companySize} handleChange={onFilterChange.bind(this)}
                           sectionName='companySize' options={companySizeOptions} placeholder='companySizePlaceholderText' />
          </Col>
          <Col xl='lg' lg='4' md='6' sm='12' xs='12'>
            <SelectSection value={countries} handleChange={onFilterChange.bind(this)} multiple
                           sectionName='countries' options={parsedCountries} placeholder='targetCountryPlaceholderText' />
          </Col>
          <Col xl='lg' lg='4' md='6' sm='12' xs='12'>
            <SelectSection value={services} handleChange={onFilterChange.bind(this)} multiple
                           sectionName='services' options={parsedServices} placeholder='serviceOfferedPlaceholderText' />
          </Col>
        </Row>
			</div>
		)
	}

}

export default SearchFilters;