import React from "react";
import {IntlProvider} from "react-intl";
import Public from "./Public";
import localeData from "../../../i18n/data.json";

class ChangeLanguage extends React.Component {
  constructor(props) {
    super(props);
    this.localStorageUpdated = this.localStorageUpdated.bind(this);
    this.state = {
      locale: "en"
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      var lng = localStorage.getItem("lng");
      if (lng) {
        this.setState({ locale: localStorage.getItem("lng") });
      }
      window.addEventListener("storage", this.localStorageUpdated);
    }
  }

  localStorageUpdated() {
    this.setState({ locale: localStorage.getItem("lng") });
  }

  render() {
    var lang = this.state.locale;

    return (
      <IntlProvider locale="en" messages={localeData[lang]} key={lang}>
        <Public />
      </IntlProvider>
    );
  }
}
export default ChangeLanguage;
