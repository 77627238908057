import React from 'react';
import {FormattedMessage} from 'react-intl';

class RegistrationThanksPage extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		if (localStorage.getItem('lng') === 'en') {
			document.title = 'Thank you!'
		} else { document.title = "Kiitos!" }
	}

	render() {
		return (
			<div className='text-center limit-width-and-center py-5 px-2'>
				<div name='form-header' className='bottom-margin'>
					<h1 className='bottom-margin-small'> <FormattedMessage id='registrationThanksHeader' /> </h1>
					<div className='text-start bottom-margin'>
						<FormattedMessage id='registrationThanksLead' />
					</div>
					<div className='text-start bottom-margin'>
						<p>
							<FormattedMessage id='registrationThanksP1' />
						</p>
					</div>
				</div>
			</div>
		);
	}

}

export default RegistrationThanksPage;