const doRequest = (type, data, url, onSuccess, onFail) => {
  var xmlhttp = new XMLHttpRequest();
  xmlhttp.open(type, url, true);

  if (type === "POST") {
    xmlhttp.setRequestHeader("Content-type", "application/json; charset=utf-8");
  }

  xmlhttp.onload = () => {
    if (xmlhttp.status < 300) {
      var responseText = xmlhttp.responseText;
      var responseData = null;
      if (responseText) {
        try {
          // Try parsing JSON, so that app doesn't crash if can't parse
          responseData = JSON.parse(responseText);
        } catch (e) {
          responseData = null;
        }
      }
      console.log('HttpRequestUtil: request ok');
      onSuccess(responseData);
    } else {
      console.log('HttpRequestUtil: request failed');
      onFail();
    }
  };

  xmlhttp.send(data);
};

const HttpRequestUtil = {
  get(url, successCallback, failureCallback) {
    doRequest("GET", null, url, successCallback, failureCallback);
  },

  post(url, data, successCallback, failureCallback) {
    doRequest("POST", data, url, successCallback, failureCallback);
  },

  delete(url, successCallback, failureCallback) {
    doRequest("DELETE", null, url, successCallback, failureCallback);
  }
};

export default HttpRequestUtil;
