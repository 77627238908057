import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
//import 'react-select/dist/react-select.css';
import AddCompanyForm from '../components/AddCompanyForm';
import AddInnoForm from '../components/AddInnoForm';
import EmailForm from '../components/EmailForm';
import EmailThanksPage from '../components/EmailThanksPage';
import EditThanksPage from '../components/EditThanksPage';
import RegisterCompany from '../components/RegisterCompany';
import RegistrationThanksPage from '../components/RegistrationThanksPage';
import ViewCompanyPage from '../components/ViewCompanyPage';
import App from '../components/App';
import MainPage from '../components/MainPage';
import AboutPage from '../components/AboutPage';
import SearchPage from '../components/SearchPage';
import SearchInnoPage from '../components/SearchInnoPage';
import HowToExpertPage from '../components/HowToExpertPage';
import {HashRouter, Navigate, Route, Routes} from 'react-router-dom';

import '../../styles/main.css';


class Public extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {

		return (
			<HashRouter>
				<App>
					<Routes>
						<Route exact path="/" element={<Navigate replace to="/home" />} />
						<Route path='/home' element={<MainPage />} />
						<Route path='/confirm' element={<EmailForm />} />
						<Route path='/register/:id' element={<RegisterCompany />} />
						<Route path='/registerES/:id' element={<AddCompanyForm />} />
						<Route path='/registerInno/:id' element={<AddInnoForm />} />
						<Route path='/profile/:id' element={<ViewCompanyPage />} />
						<Route path='/emailcomplete' element={<EmailThanksPage />} />
						<Route path='/registrationcomplete' element={<RegistrationThanksPage />} />
						<Route path='/editcomplete' element={<EditThanksPage />} />
						<Route path='/about' element={<AboutPage />} />
						<Route path='/howto' element={<HowToExpertPage />} />
						<Route path='/search' element={<SearchPage />} />
						<Route path='/searchInno' element={<SearchInnoPage />} />
					</Routes>
				</App>
			</HashRouter>

		);
	}
}

export default Public;