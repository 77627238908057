import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Alert, Button, Col, FormGroup, Input, Label, Row} from 'reactstrap';

import HttpRequestUtil from '../../util/HttpRequestUtil';
import {parseCompanyData} from '../../util/CompanyDataParser';

import pdf1 from '../../../files/BF_Expert_Search_Terms_of_Use_Experts_070119.pdf';
import pdf1_fi from '../../../files/FIN_BF_Expert_Search_Terms_of_Use_Experts_070119.pdf';
import pdf2 from "../../../files/Privacy_Notice_070119.pdf";
import pdf2_fi from "../../../files/FIN_Privacy_Notice_070119.pdf";

import validator from 'validator';

class SubmitSection extends React.Component {
	constructor(props) {
		super(props);

		this.state = { accept: false, clicked: false, sending: false };
	}

	sendCompanyInfo(valid, optionalInvalid, expertInvalid, accept, e) {
		e.preventDefault();

		var companyData = this.props.company;
		companyData.user = this.props.user;

		var jsonData = JSON.stringify(parseCompanyData(companyData));

		this.setState({ clicked: true });

		this.props.sending(true);

		//HttpRequestUtil.post('/api/company', jsonData, this.props.sendSuccess.bind(this), this.props.setError.bind(this));
		if (valid && !optionalInvalid && !expertInvalid && accept) {
			HttpRequestUtil.post('/api/company', jsonData, this.props.sendSuccess.bind(this), this.props.setError.bind(this));
			this.setState({ sending: true });
		}
	}


	checkData(company) {
		if (company) {
			if (company.name && company.businessId && company.country && company.city && company.description &&
				company.established && company.turnover && company.employeeCount && company.address &&
				company.services && company.services.length > 0 && company.industries && company.industries.length > 0 &&
				company.countries && company.countries.length > 0 && company.serviceLanguage && company.descriptionCountries &&
				company.descriptionServices && company.descriptionIndustries) {

				if ((company.country.value === "FIN") && !this.checkBusinessId(company.businessId)) {
					return false;
				}

				return true;
			}
		}

		return false;
	}

	checkBusinessId(value) {
		if (value.match(/[0-9]{7}\-[0-9]{1}/) && value.length === 9) {
			var stringArray = value.split("");
			var sum = (stringArray[0] * 7) + (stringArray[1] * 9) + (stringArray[2] * 10) + (stringArray[3] * 5) + (stringArray[4] * 8) + (stringArray[5] * 4) + (stringArray[6] * 2);
			var remainder = sum % 11;
			var controlNum = parseInt(stringArray[8]);
			if ((remainder === 0) && controlNum === 0) {
				return true;
			} else if (remainder === 1) {
				return false;
			} else if (11 - remainder === controlNum) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	optionalDataInvalid(company) {
		if (company.webpage && !validator.isURL(company.webpage)) {
			return true;
		}

		if (company.linkedin && !validator.isURL(company.linkedin)) {
			return true;
		}

		if (company.facebook && !validator.isURL(company.facebook)) {
			return true;
		}

		if (company.publications && company.publications.length > 0) {
			for (var i = 0; i < company.publications.length; i++) {
				if (!validator.isURL(company.publications[i])) {
					return true;
				}
			}
		}

		return false;
	}

	expertsInvalid(experts) {
		if (!experts || experts.length < 1) {
			return true;
		}

		for (var i = 0; i < experts.length; i++) {
			if (!experts[i].name || !experts[i].title || !experts[i].phone || !validator.isEmail('' + experts[i].email)) {
				return true;
			}
			if (experts[i].link && !validator.isURL(experts[i].link)) {
				return true;
			}
		}

		return false;
	}

	handleAcceptChange() {
		var checked = !this.state.accept;
		this.setState({ accept: checked });
	}

	render() {
		const {
			company,
			user,
			loading,
			error,
			loadFailed,
			isRelatedMaterialSectionValid
		} = this.props;

		const {
			clicked,
			accept,
			sending
		} = this.state;

		var valid = this.checkData(company);
		var optionalInvalid = this.optionalDataInvalid(company);
		var expertInvalid = this.expertsInvalid(company.experts);

		return (
			//https://stackoverflow.com/questions/68573738/attempted-import-error-jumbotron-is-not-exported-from-react-bootstrap-impor
			<div className='jumbotron-1'>
				<h4>
					{company.uid
						? <FormattedMessage id='saveChanges' />
						: <FormattedMessage id='confirmRegistration' />
					}
				</h4>
				<p> <FormattedMessage id='confirmText' values={{
					link1: <a target='_blank' href={pdf1}><FormattedMessage id="termsAndCond" /></a>,
					link2: <a target='_blank' href={pdf1_fi}><FormattedMessage id="termsAndCond" /></a>,
					link3: <a target='_blank' href={pdf2}><FormattedMessage id="privacy" /></a>,
					link4: <a target='_blank' href={pdf2_fi}><FormattedMessage id="privacy" /></a>
				}} /> </p>
				<FormGroup check color={(!accept && clicked) ? 'danger' : ''}>
					<Label check>
						<Input type='checkbox' checked={accept} onChange={this.handleAcceptChange.bind(this)} />{' '}
						<FormattedMessage id='acceptTerms' />
					</Label>
				</FormGroup>
				<Row>
					<Col sm='7'>
						<Button block color='primary' onClick={this.sendCompanyInfo.bind(this, valid, optionalInvalid, expertInvalid, accept)}>
							{sending
								? <FormattedMessage id='loading' />
								: <FormattedMessage id='submitForm' />
							}
						</Button>
					</Col>
				</Row>
				<br></br>
				{company.uid ?
				<div className='italic'>
					<FormattedMessage id='saveOld' />
				</div> : null}
				{!valid && clicked
					? <Alert color='danger'><FormattedMessage id='dataInvalid' /> </Alert>
					: null}
				{optionalInvalid && clicked
					? <Alert color='danger'><FormattedMessage id='optDataInvalid' /> </Alert>
					: null}
				{expertInvalid && clicked
					? <Alert color='danger'><FormattedMessage id='expertDataInvalid' /> </Alert>
					: null}
				{!isRelatedMaterialSectionValid && clicked
					? <Alert color='danger'><FormattedMessage id='relatedDataInvalid' /> </Alert>
					: null}
				{loadFailed ?
					<Alert color='danger'><FormattedMessage id='loadFailed' /> </Alert>
					: null}
			</div>
		);
	}

}

export default SubmitSection;