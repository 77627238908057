import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Container} from 'reactstrap';

class ContactUs extends React.Component {
	constructor(props) {
		super(props);
	}

	render () {
		const { formatMessage } = this.props.intl;
		return (
			<Container fluid className='text-center'>
				<div className='limit-width-and-center py-5 px-2'>
					<h2 className='text-uppercase'>
						<FormattedMessage id='contactUs' />
					</h2>
					<p>
						<FormattedMessage id='contactText1' />
					</p>
					<p>
						<FormattedMessage id='contactText2' />
					</p>
					<a href={formatMessage({id:'contactUsURL'})} className='btn btn-primary text-uppercase' target='_blank'>
						<FormattedMessage id='becomeCustomer' />
					</a>
				</div>
			</Container>
		)
	}

}

export default injectIntl(ContactUs);
