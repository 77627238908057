import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import bflogo from "../../images/bf_logo_blue_rgb.png";
import logo from '../../images/expert_search_logo.svg';
import burger from '../../images/burger.svg';


class Header extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
			dropdownOpen: false,
			locale: 'en'
		}
	}

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}

	close() {
		this.setState({
			isOpen: false,
		});
	}

	locale(value) {
		localStorage.setItem('lng', value);
		window.location.reload();

	}

	toggleDropdown() {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen
		});
	}


	render() {
		return (
			<Navbar className='p-0' expand='sm'>
				<NavbarToggler right="true" onClick={this.toggle.bind(this)} className='m-2'>
					<img src={burger} alt='menu' />
				</NavbarToggler>
				<NavbarBrand href='/' className='headerlogo ps-4 pt-2'>
					<div><img src={bflogo} alt='Business Finland' style={{ width: "80px", paddingBottom: "10px" }} /></div>
					<div><img src={logo} alt='Expert Search' /></div>
				</NavbarBrand>
				<Collapse isOpen={this.state.isOpen} className='collapsebox' navbar>
					<Nav navbar style={{ width: '100%' }} className='justify-content-start'>
						<NavItem className='pt-2 mx-2'>
							<NavLink to='/home' className='nav-link' onClick={this.close.bind(this)}>
								<FormattedMessage id='home' />
							</NavLink>
						</NavItem>
						<NavItem className='pt-2 mx-2'>
							<NavLink to='/about' className='nav-link' onClick={this.close.bind(this)}>
								<FormattedMessage id='about' />
							</NavLink>
						</NavItem>
						<NavItem className='pt-2 mx-2'>
							<NavLink to='/howto' className='nav-link' onClick={this.close.bind(this)}>
								<FormattedMessage id='howToExpertHeader' />
							</NavLink>
						</NavItem>
						<NavItem className='pt-2 mx-2'>
							<NavLink to='/search' className='nav-link' onClick={this.close.bind(this)}>
								<FormattedMessage id='searchHeader' />
							</NavLink>
						</NavItem>

						<NavItem className='pt-2 mx-2'>
							<NavLink to='/confirm' className='nav-link' onClick={this.close.bind(this)}>
								<FormattedMessage id='registerExpert' />
							</NavLink>
						</NavItem>
						<NavItem className='pt-2 px-2' style={{ marginLeft: 'auto' }}>
							<NavLink to="" className='nav-link' onClick={this.locale.bind(this, 'fi')}>
								<FormattedMessage id='FI' />
							</NavLink>
						</NavItem>
						<NavItem className='pt-2 px-2' style={{ marginRight: '15px' }}>
							<NavLink to="" className='nav-link' onClick={this.locale.bind(this, 'en')}>
								<FormattedMessage id='EN' />
							</NavLink>
						</NavItem>

					</Nav>
				</Collapse>
			</Navbar>
		)
	}

}

export default Header;
