import {base64StringToBlob} from 'blob-util';
import _ from 'lodash';
import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, Form} from 'reactstrap';
import {parseFileToObject} from '../../util/CompanyDataParser';
import HttpRequestUtil from '../../util/HttpRequestUtil';
import {countriesList, industriesList, servicesList} from '../../util/SelectOptions';
import TextInputSection from '../TextInputSection';
import BasicInformationSection from './BasicInformationSection';
import CompanyInformationSection from './CompanyInformationSection';
import ContactPersonsSection from './ContactPersonsSection';
import LinksSection from './LinksSection';
import RelatedMaterialsSection from './RelatedMaterialsSection';
import ServicesSection from './ServicesSection';
import SubmitSection from './SubmitSection';
import {RouterHOC} from "../RouterHOC";


const emptyExpert = { name: '', information: '', photo: '', title: '', phone: '', email: '' };

const emptyCompany = {
	name: null, businessId: null, address: null, city: null, country: null,
	webpage: null, facebook: null, linkedin: null, established: null, logo: null, turnover: null, employeeCount: null, description: null,
	serviceLanguage: null, membership: null, publications: null, references: null, cvs: null, experts: [Object.assign({}, emptyExpert)],
	countries: null, descriptionCountries: null, services: null, descriptionServices: null, industries: null, descriptionIndustries: null,
	inno: false
};



class AddCompanyForm extends React.Component {
	constructor(props) {
		super(props);

		var initialCompany = Object.assign({}, emptyCompany);

		this.state = {
			company: initialCompany,
			loading: false,
			logo: '',
			accept: false,
			user: null,
			deleteList: { cvs: [], references: [] },
			countriesHelper: '',
			servicesHelper: '',
			industriesHelper: '',
			relatedMaterialsValid: true,
		};
	}

	componentDidMount() {
		this.getUserData();
		if (localStorage.getItem('lng') === 'en') {
			document.title = 'Register your company';
		} else { document.title = "Rekisteröi yrityksesi"; }
	}

	redirectToEmailForm() {
		this.props.navigate('/confirm');
	}

	getUserData() {
		var uid = this.props.params.id;
		this.getUser(uid);

		if (uid) {
			this.getUser(uid);
		} else {
			this.redirectToEmailForm();
		}
	}

	getUser(uid) {
		this.setState({ loading: true });
		HttpRequestUtil.get('./api/user/' + uid, this.setUser.bind(this), this.redirectToEmailForm.bind(this));
	}

	setUser(data) {
		var user = data;

		if (!user) {
			this.redirectToEmailForm();
		} else {
			this.setState({ user: user, loading: false });

			var companyUid = user.companyUid;
			if (companyUid) {
				this.getExistingCompany(companyUid);
			} else {
				var company = this.state.company;
				if (this.state.user && this.state.user.email.indexOf('@businessfinland.fi') > -1) {
					company.services = ['finpro'];
				} else if (this.state.user && this.state.user.email.indexOf('@formin.fi') > -1) {
					company.services = ['embassy'];
				}

				this.setState({ company: company });
			}
		}
	}

	getExistingCompany(uid) {
		this.setState({ loading: true });
		HttpRequestUtil.get('./api/company/' + uid, this.setCompany.bind(this), this.setError.bind(this));
	}

	setCompany(data) {
		var company = data[0];

		company.experts = (company.experts && company.experts.length !== 0) ? company.experts : [Object.assign({}, emptyExpert)];
		company.publications = company.publications[0] ? company.publications : null;
		var servicesHelper = (company.services != null && company.services.length) > 0 ? this.parseHelper(company.services) : '';
		var countriesHelper = (company.countries != null && company.countries.length) > 0 ? this.parseHelper(company.countries) : '';
		var industriesHelper = (company.industries != null && company.industries.length) > 0 ? this.parseHelper(company.industries) : '';
		this.setState({
			company: company,
			loading: false,
			logo: '',
			servicesHelper: servicesHelper,
			countriesHelper: countriesHelper,
			industriesHelper: industriesHelper
		});
		this.parseImage(company.logo);
	}

	parseHelper(services) {
		const { formatMessage } = this.props.intl;
		var helperList = services.map(key => formatMessage({ id: key }));
		return helperList;
	}

	parseImage(baseImg) {
		if (!baseImg) { return; }
		const blobUrl = URL.createObjectURL(base64StringToBlob(baseImg));
		this.setState({ logo: blobUrl });
	}

	setError() {
		this.setState({ loading: false, loadFailed: true });
	}

	toggleLoading() {
		var lastState = this.state.loading;
		this.setState({ loading: !lastState });
	}

	handleChange(key, e) {
		e.preventDefault();
		var companyCopy = this.state.company;
		companyCopy[key] = e.target.value;
		this.setState({ company: companyCopy });
	}

	// hard limitation of 5 selections for certain selector fields in ServicesSection
	handleSelectChangeLimited(key, value) {
		var companyCopy = this.state.company;
		companyCopy[key] = value;
		var text = '';
		var helperArray = [];

		if (companyCopy[key].length < 6) {
			for (let i = 0; i < companyCopy[key].length; i++) {
				text = value[i].label;
				helperArray.push(text);
			}
			this.setState({
				company: companyCopy,
				[key + "Helper"]: helperArray
			});
		} else if (companyCopy[key].length === 6) {
			companyCopy[key] = _.dropRight(companyCopy[key]);
		}
	}


	handleSelectChange(key, value) {
		var companyCopy = this.state.company;
		companyCopy[key] = value;
		this.setState({ company: companyCopy });
	}

	handleFileChange(key, acceptedFiles, rejectedFiles) {

		var companyCopy = this.state.company;
		companyCopy[key] = [];

		if (key === 'logo' && acceptedFiles.length > 0) {
			this.setState({ company: companyCopy, loading: true });
			this.getLogo(acceptedFiles[0]);
		} else if (key === 'logo' && rejectedFiles.length > 0) {
			companyCopy.logo = null;
			this.setState({ company: companyCopy });
		} else {
			this.setState({ company: companyCopy, loading: true });
			acceptedFiles.map(file => (parseFileToObject(file, key, this.pushFile.bind(this))));
		}
	}

	getLogo(imgFile) {
		var reader = new FileReader();

		reader.addEventListener('load', () => {
			var result = reader.result;

			var companyCopy = this.state.company;
			companyCopy.logo = result.substring(result.indexOf(',') + 1);

			this.setState({ logo: result, company: companyCopy, loading: false });
		}, false);

		reader.readAsDataURL(imgFile);
	}

	removeLogo() {
		var companyCopy = this.state.company;
		companyCopy.logo = null;
		this.setState({ company: companyCopy, logo: '' });
	}

	pushFile(file, key) {
		var companyCopy = this.state.company;
		companyCopy[key].push(file);
		this.setState({ loading: false, company: companyCopy });
	}

	redirectToThanks() {
		if (this.state.company.uid) {
			this.state.deleteList.cvs.map(uid => (HttpRequestUtil.delete('/api/cv/' + uid, () => { }, () => { })));
			this.state.deleteList.references.map(uid => (HttpRequestUtil.delete('/api/reference/' + uid, () => { }, () => { })));

			this.props.navigate('/editcomplete');
		} else {
			this.props.navigate('/registrationcomplete');
		}
		this.setState({ loadFailed: false });
	}

	parseOptions(stringArray) {
		const { formatMessage } = this.props.intl;

		return _.orderBy(stringArray.map(key => (
			{ value: key, label: formatMessage({ id: key }) })), "label", "asc");

	}

	addExpert(e) {
		e.preventDefault();
		var companyCopy = this.state.company;
		companyCopy.experts.push(Object.assign({}, emptyExpert));
		this.setState({ company: companyCopy });
	}

	setExpertInfo(index, key, value) {
		var companyCopy = this.state.company;
		companyCopy.experts[index][key] = value;
		this.setState({ company: companyCopy });
	}

	removePhoto(index) {
		var companyCopy = this.state.company;
		companyCopy.experts[index].photo = null;
		this.setState({ company: companyCopy });
	}

	removeExpertInfo(index) {
		var companyCopy = this.state.company;
		var id = companyCopy.experts[index].id;
		HttpRequestUtil.delete('/api/expert/' + id, () => { }, () => { });
		companyCopy.experts.splice(index, 1);
		this.setState({ company: companyCopy });
	}

	addPublication(publicationUrl, e) {
		e.preventDefault();
		if (!this.state.relatedMaterialsValid) {
			console.log("related not valid");
			return;
		}
		var companyCopy = this.state.company;
		companyCopy.publications = companyCopy.publications || [];
		companyCopy.publications.push(publicationUrl);
		this.setState({ company: companyCopy });
	}

	removePublication(index) {
		var companyCopy = this.state.company;
		companyCopy.publications.splice(index, 1);
		if (companyCopy.publications.length === 0) {
			companyCopy.publications = null;
		}
		this.setState({ company: companyCopy });
	}

	removePdf(index, uid, key) {
		var deleteList = this.state.deleteList;
		deleteList[key].push(uid);

		var companyCopy = this.state.company;
		companyCopy[key].splice(index, 1);
		if (companyCopy[key].length === 0) {
			companyCopy[key] = null;
		}
		this.setState({ company: companyCopy });
	}

	sending(value) {
		this.setState({ loading: true, submitClicked: value });
	}

	returnToRegister(e) {
		e.preventDefault();
		this.props.navigate('/register/' + this.props.params.id);
	}

	setRelatedMaterialsValid(valid) {
		console.log("setting validity", valid);
		this.state.relatedMaterialsValid = valid;
	}

	render() {
		var company = this.state.company;
		var parsedServices = this.parseOptions(servicesList);
		var parsedIndustries = this.parseOptions(industriesList);
		var parsedCountries = this.parseOptions(countriesList);

		return (
			<div>
				{!this.state.company.uid ?
					<Button onClick={this.returnToRegister.bind(this)} className="backButton">
						<FormattedMessage id='backToReg' />
					</Button>
					: null}
				<div className='text-center px-2 limit-width-and-center mt-4'>
					<div name='form-header' className='bottom-margin'>
						<h1 className='bottom-margin-small'>
							{this.state.company.uid
								? <FormattedMessage id='modifyYourCompany' />
								: <FormattedMessage id='registerYourCompany' />
							}
						</h1>
						{this.state.company.uid
							? null
							: <div className='text-start'> <FormattedMessage id='formIntro' /> <div className='top-margin-small'> <FormattedMessage id='completeInEng' /> </div> </div>
						}
					</div>

					<Form className='text-start'>

						<BasicInformationSection user={this.state.user} company={this.state.company} logoUrl={this.state.logo} submitClicked={this.state.submitClicked} countries={parsedCountries} handleChange={this.handleChange.bind(this)} handleSelectChange={this.handleSelectChange.bind(this)} handleFileChange={this.handleFileChange.bind(this)} removeLogo={this.removeLogo.bind(this)} />
						<ServicesSection user={this.state.user} company={this.state.company} industries={parsedIndustries} services={parsedServices} countries={parsedCountries} submitClicked={this.state.submitClicked} handleChange={this.handleChange.bind(this)} handleSelectChangeLimited={this.handleSelectChangeLimited.bind(this)} servicesHelper={this.state.servicesHelper} industriesHelper={this.state.industriesHelper} countriesHelper={this.state.countriesHelper} />
						<CompanyInformationSection company={this.state.company} handleChange={this.handleChange.bind(this)} submitClicked={this.state.submitClicked} handleSelectChange={this.handleSelectChange.bind(this)} />

						<div name='addressSection' className='bottom-margin'>
							<h5> <FormattedMessage id='addressHeader' /> </h5>
							<TextInputSection sectionName='address' value={this.state.company.address} submitClicked={this.state.submitClicked} handleChange={this.handleChange.bind(this)} maxLength={200} placeholder='placeholderAddress' />
						</div>

						<LinksSection company={this.state.company} handleChange={this.handleChange.bind(this)} submitClicked={this.state.submitClicked} />
						<ContactPersonsSection company={this.state.company} addExpert={this.addExpert.bind(this)} removeExpertInfo={this.removeExpertInfo.bind(this)} setExpertInfo={this.setExpertInfo.bind(this)} removePhoto={this.removePhoto.bind(this)} submitClicked={this.state.submitClicked} />
						<RelatedMaterialsSection company={this.state.company} addPublication={this.addPublication.bind(this)} removePublication={this.removePublication.bind(this)} handleFileChange={this.handleFileChange.bind(this)} removePdf={this.removePdf.bind(this)} submitClicked={this.state.submitClicked} setSectionValid={this.setRelatedMaterialsValid.bind(this)} />

						<SubmitSection company={this.state.company} user={this.state.user} loading={this.state.loading} sending={this.sending.bind(this)} sendSuccess={this.redirectToThanks.bind(this)} setError={this.setError.bind(this)} loadFailed={this.state.loadFailed} isRelatedMaterialSectionValid={this.state.relatedMaterialsValid} />
					</Form>

				</div>
				{!this.state.company.uid ?
					<Button onClick={this.returnToRegister.bind(this)} className="backButton">
						<FormattedMessage id='backToReg' />
					</Button>
					: null}
			</div>
		);
	}

}

export default _.flowRight(injectIntl, RouterHOC)(AddCompanyForm);