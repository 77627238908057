import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Button, Col, FormGroup} from 'reactstrap';
import ContactInput from './ContactInput';

class ContactPersonsSection extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {
			company,
			addExpert,
			setExpertInfo,
			removeExpertInfo,
			removePhoto,
			submitClicked,
		} = this.props;

		return (
			<div name='contactPersonsSection' className='bottom-margin'>
				<h5> <FormattedMessage id='contactPerson' /> </h5>
				<FormGroup>
					{company.experts.map((expert, index) => (
						<ContactInput key={'expert' + index} index={index} setExpertInfo={setExpertInfo.bind(this)} removeExpertInfo={removeExpertInfo.bind(this)} name={expert.name} title={expert.title} allExperts={company.experts.length} email={expert.email} link={expert.link} phone={expert.phone} information={expert.information} photo={expert.photo} removePhoto={removePhoto.bind(this)} submitClicked={submitClicked} />))
					}
				</FormGroup>
				<FormGroup>
					<Col sm='11'>
						<Button color='primary' onClick={addExpert.bind(this)}> <FormattedMessage id='addExpert' /></Button>
					</Col>
				</FormGroup>
			</div>
		);
	}

}

export default ContactPersonsSection;