import {base64StringToBlob} from 'blob-util';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {CardImg, Container, Pagination, PaginationItem, PaginationLink, Row} from 'reactstrap';
import logoBF from '../../../images/bf_logo_blue_rgb.png';
import logoPlaceholder from '../../../images/logo-placeholder.svg';
import logoFormin from '../../../images/Su-Ru_CMYK.png';
import SearchResultCard from './SearchResultCard';
import {RouterHOC} from "../RouterHOC";

const resultsPerPage = 24;

class SearchResults extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			results: [], otherResults: [], teamFinland: [], error: null, logos: [],
			visibleResults: [], visibleResultsOther: [], visibleResultsTeamFinland: [],
			page: 0, pageCount: 0
		}
	}

	componentDidMount() {
		this.setResults(this.props.results, this.props.otherResults, this.props.teamFinland);

	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		this.setResults(nextProps.results, nextProps.otherResults, nextProps.teamFinland);
	}

	getLogos(resultsToParse) {
		this.setState({ logos: [] });
		for (var i = 0; i < resultsToParse.length; i++) {
			this.parseImage(resultsToParse[i].logo, i);
		}
	}

	setResults(results, otherResults, teamFinland) {
		var visibleResults = this.getResultsPerPage(results, 0, resultsPerPage);
		var visibleResultsOther = (visibleResults.length < resultsPerPage) ? this.getResultsPerPage(otherResults, 0, resultsPerPage - visibleResults.length) : [];

		var visibleResultsTeamFinland = this.getResultsPerPage(teamFinland, 0, teamFinland.lastIndex);

		this.getLogos(visibleResults.concat(visibleResultsOther).concat(visibleResultsTeamFinland));

		var resultLength = results ? results.length : 0;
		var otherLength = otherResults ? otherResults.length : 0;
		var pageCount = Math.ceil((resultLength + otherLength) / resultsPerPage) || 0;

		this.setState({
			results: results,
			otherResults: otherResults,
			teamFinland: teamFinland,
			error: null,
			pageCount: pageCount,
			page: 1,
			visibleResults: visibleResults,
			visibleResultsOther: visibleResultsOther,
			visibleResultsTeamFinland: visibleResultsTeamFinland
		});
	}

	setError(error) {
		this.setState({ results: [], error: 'empty', logos: [] });
	}

	getResultsPerPage(results, start, end) {
		return (results && results.length > 0) ? results.slice(start, end) : [];
	}

	parseImage(baseImg, index) {
		if (!baseImg) { return; }

		const blobUrl = URL.createObjectURL(base64StringToBlob(baseImg));
		let logos = this.state.logos;
		logos[index] = blobUrl;
		this.setState({ logos: logos });
	}

	changePage(page, e) {
		if (page === this.state.page) {
			e.preventDefault();
			return;
		}

		e.preventDefault();

		var exactStartIndex = (page - 1) * resultsPerPage;
		var exactEndIndex = page * resultsPerPage;

		var visible = this.getResultsPerPage(this.state.results, exactStartIndex, exactEndIndex);

		//calculate paging for other results after reaching end of visible exact match results
		var lastPageWithExactMatches = Math.ceil(this.state.results.length / resultsPerPage);
		var pageCountFromEndOfExactMatches = page - lastPageWithExactMatches;
		var exactMatchesOnLastPage = this.state.results.length % resultsPerPage;

		var otherStartIndex = (page === lastPageWithExactMatches) ? 0 : (resultsPerPage * (pageCountFromEndOfExactMatches - 1) + resultsPerPage - exactMatchesOnLastPage);
		var otherEndIndex = (page === lastPageWithExactMatches) ? otherStartIndex + resultsPerPage - exactMatchesOnLastPage : otherStartIndex + resultsPerPage;

		var visibleOther = visible.length < resultsPerPage
			? this.getResultsPerPage(this.state.otherResults, otherStartIndex, otherEndIndex)
			: [];

		var visibleTeamFinland = this.getResultsPerPage(this.state.teamFinland, 0, this.state.teamFinland.length);

		this.setState({ visibleResults: visible, page: page, visibleResultsOther: visibleOther, visibleResultsTeamFinland: visibleTeamFinland });
		this.getLogos(visible.concat(visibleOther).concat(visibleTeamFinland));
		this.resultStart.scrollIntoView({ behavior: "smooth" });
	}

	getLabel(key, parsedArray) {
		if (!parsedArray || parsedArray.length === 0) {
			return null;
		}
		var match = parsedArray.find(parsed => (parsed.value === key));
		return match ? match.label : "";
	}

	getPages() {
		var pagesLength = this.state.pageCount;
		var pages = [];
		if (this.state.page !== 1 && pagesLength > 1) {
			pages.push(<PaginationItem key='pageprev'>
				<PaginationLink href="#" previous onClick={this.changePage.bind(this, this.state.page - 1)} />
			</PaginationItem>);
		}

		for (var i = 1; i <= pagesLength; i++) {
			pages.push(
				<PaginationItem key={'page' + i} className={this.state.page === i ? 'active' : ''}>
					<PaginationLink href="#" onClick={this.changePage.bind(this, i)}>
						{i}
					</PaginationLink>
				</PaginationItem>
			);
		}

		if (this.state.page < pagesLength) {
			pages.push(<PaginationItem key='pagenext'>
				<PaginationLink href="#" next onClick={this.changePage.bind(this, this.state.page + 1)} />
			</PaginationItem>);
		}

		return (pages
			? <Pagination className='justify-content-center'>
				{pages}
			</Pagination>
			: null);
	}

	searchCriteriaSelected() {
		return (
			(this.props.services && this.props.services.length > 0)
			|| (this.props.industries && this.props.industries.length > 0)
			|| (this.props.location && this.props.location.length > 0)
			|| (this.props.countries && this.props.countries.length > 0)
			|| this.props.companyName
			|| this.props.expertName
			|| this.props.textSearch
			|| this.props.serviceLanguage
		);
	}

	getLogoOrPlaceholder(index, areExactMatches, company) {
		if (areExactMatches && this.state.logos[index]) {
			return <CardImg top className='logo' src={this.state.logos[(index)]} alt={company.name} />;
		} else if (company.teamFinland && this.state.logos[this.state.visibleResults.length + this.state.visibleResultsOther.length + index]) {
			return <img className='logo' src={this.state.logos[this.state.visibleResults.length + this.state.visibleResultsOther.length + index]} alt={company.name} />;
		} else if (!areExactMatches && this.state.logos[this.state.visibleResults.length + index]) {
			return <CardImg top className='logo' src={this.state.logos[(this.state.visibleResults.length + index)]} alt={company.name} />;
		} else if (company.user && company.user.email.indexOf('@businessfinland.fi') > -1) {
			return <img className='logo' src={logoBF} alt='businessfinland' />;
		} else if (company.user && company.user.email.indexOf('@formin.fi') > -1) {
			return <img className='logo' src={logoFormin} alt='formin' />;
		} else {
			return <img src={logoPlaceholder} alt='logo-placeholder' className='logo' />;
		}
	}

	getMatches(parsedCountries, parsedServices, parsedIndustries, resultAmount, visibleResults, areExactMatches) {

		return <span>
			{(this.searchCriteriaSelected() && areExactMatches)
				? <div> <FormattedMessage id='exactMatches' /> ({resultAmount}) </div>
				: null}

			{!areExactMatches
				? (visibleResults[0].teamFinland
					? <span>
						<hr className='group-divider' />
						<FormattedMessage id='teamFinland' /> ({resultAmount})
							</span>

					: <span>
						<hr className='group-divider' />
						<FormattedMessage id='otherMatches' /> ({resultAmount})
							</span>)

				: null
			}

			<Row className='justify-content-start'>
				{(visibleResults && visibleResults.length > 0)
					? visibleResults.map((result, index) => (
						<SearchResultCard key={'result' + index}
							index={index}
							result={result}
							logo={this.getLogoOrPlaceholder(index, areExactMatches, result)}
							parsedIndustries={parsedIndustries}
							parsedServices={parsedServices}
							parsedCountries={parsedCountries}
							countries={this.props.countries}
							services={this.props.services}
							industries={this.props.industries}
							queryString={this.props.queryString} />
					))
					: null}
			</Row>
		</span>;
	}

	render() {
		var {
			services,
			industries,
			location,
			parsedCountries,
			parsedServices,
			parsedIndustries,
			queryString,
		} = this.props;

		return (
			<Container fluid className='py-3 px-4 bg-faded dot-background-light'>
				<div style={{ float: "left", clear: "both" }} ref={(scrollertop) => { this.resultStart = scrollertop; }}></div>

				{(this.state.visibleResults && this.state.visibleResults.length > 0)
					? this.getMatches(parsedCountries, parsedServices, parsedIndustries, this.state.results.length, this.state.visibleResults, true)
					: ((this.state.page > 1 || !queryString) ? null : <FormattedMessage id='noResults'>{(txt) => (<span className="noResults">{txt}</span>)}</FormattedMessage>)
				}

				{(this.state.visibleResultsOther && this.state.visibleResultsOther.length > 0)
					? this.getMatches(parsedCountries, parsedServices, parsedIndustries, this.state.otherResults.length, this.state.visibleResultsOther, false)
					: null}

				{this.getPages()}

				{(this.state.visibleResultsTeamFinland && this.state.visibleResultsTeamFinland.length > 0)
					? this.getMatches(parsedCountries, parsedServices, parsedIndustries, this.state.teamFinland.length, this.state.visibleResultsTeamFinland, false)
					: null
				}

			</Container>
		)
	}

}

export default RouterHOC(SearchResults);
