import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, Container} from 'reactstrap';

import HttpRequestUtil from '../../util/HttpRequestUtil';
import {companySizeOptions, countriesList, industriesList, servicesList} from '../../util/SelectOptions';
import SearchFilters from './SearchFilters';
import SearchResults from './SearchResults';
import _ from 'lodash';
import FormattedMessageHOC from "../FormattedMessageHOC";
import {RouterHOC} from "../RouterHOC";

class SearchPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			search: [],
			companyName: '',
			textSearch: '',
			serviceLanguage: '',
			location: [],
			expertName: '',
			industries: [],
			countries: [],
			services: [],
			companySizeOptions: companySizeOptions,
			results: [],
			loading: false,
			teamFinland: [],
			error: null,
			queryString: ''
		};
	}

	componentDidMount() {

		//getCountries(this.setCountries.bind(this), this.setError.bind(this));
		const { location } = this.props;
		if (location && location.search) {
			this.getFilters();
		}
		if (localStorage.getItem('lng') === 'en') {
			document.title = 'Find internationalisation consultants'
		} else { document.title = "Etsi kansainvälistymisasiantuntijaa" }

	}

	handleSearch(e) {
		e.preventDefault();
		this.setState({
			loading: true,
			queryString: this.searchToQueryString()
		});
		this.props.navigate('/search/' + this.searchToQueryString());
		HttpRequestUtil.post('../api/companies/', JSON.stringify(this.getSearchJson()), this.setResults.bind(this), this.setError.bind(this));
	}

	getResults() {
		this.setState({ loading: true });
		HttpRequestUtil.post('../api/companies/', JSON.stringify(this.getSearchJson()), this.setResults.bind(this), this.setError.bind(this));
	}

	getSearchJson() {
		var sizeStart, sizeEnd;
		var companySize = this.compSizeFromArray(this.state.companySize);
		if (companySize) {
			sizeStart = companySize.sizeStart;
			sizeEnd = companySize.sizeEnd;
		}

		var json = {
			name: this.state.companyName,
			description: this.state.textSearch,
			serviceLanguage: this.state.serviceLanguage,
			country: _.map(this.state.location, 'value'),
			expertName: this.state.expertName,
			focusIndustries: _.map(this.state.industries, 'value'),
			sizeStart: sizeStart,
			sizeEnd: sizeEnd,
			focusCountries: _.map(this.state.countries, 'value'),
			servicesOffered: _.map(this.state.services, 'value'),
			inno: false,
		};
		return json;


	}

	getFilters() {
		const { location } = this.props;
		console.log("getFilters from location")
		if (!location.search) {
			return;
		}
		var filters = new URLSearchParams(location.search.slice(1, location.search.length));
		var countryCode = this.getTextFromUrl(location, "countries=");
		var countryCodeLocation = this.getTextFromUrl(location, "location=");
		var industry = this.getTextFromUrl(location, "industries=");
		var services = this.getTextFromUrl(location, "services=");
		var size = this.getTextFromUrl(location, "companySize=");

		this.setState({
			countries: Array.isArray(countryCode) ? this.parseOptions(countryCode) : (countryCode ? this.parseOptions([countryCode]) : []),
			industries: Array.isArray(industry) ? this.parseOptions(industry) : (industry ? this.parseOptions([industry]) : []),
			services: Array.isArray(services) ? this.parseOptions(services) : (services ? this.parseOptions([services]) : []),
			location: Array.isArray(countryCodeLocation) ? this.parseOptions(countryCodeLocation) : (countryCodeLocation ? this.parseOptions([countryCode]) : []),
			companyName: filters.get("companyName") ? filters.get("companyName") : '',
			textSearch: filters.get("textSearch") ? filters.get("textSearch") : '',
			serviceLanguage: filters.get("serviceLanguage") ? filters.get("serviceLanguage") : '',
			expertName: filters.get("expertName") ? filters.get("expertName") : '',
			companySize: this.state.companySizeOptions.filter(obj => size.indexOf(obj.value) !== -1),
			queryString: location.search,
		}, this.getResults);

	}

	getTextFromUrl(location, splitfrom) {
		var searchArray = location.search.split(splitfrom);
		var c = _.last(searchArray);
		if (c.indexOf('=') > -1) {
			var d = _.first(c.split("="));
			var e = _.dropRight(d.split("&"), 1);
		} else {
			var d = _.first(c.split("="));
			var e = d.split("&");
		}
		return e;
	}

	setError(data) {
		this.setState({ error: 'unableToGetResults', loading: false });
	}

	setResults(data) {
		var results = data ? this.arrangeByName(data) : null;
		var teamFinland = data ? this.arrangeByName(data.filter(result => (result.teamFinland))) : null;
		var acceptedOnly = results ? results.filter(result => result.accepted) : null;
		this.setState({ results: acceptedOnly, teamFinland: teamFinland, loading: false, error: null });
	}

	arrangeByName(list) {
		list.sort((company1, company2) => {
			var string1 = company1['name'].toLowerCase();
			var string2 = company2['name'].toLowerCase();
			if (string1 < string2) {
				return -1;
			} else if (string1 > string2) {
				return 1;
			}
			return 0;
		});
		return list;
	}

	parseOptions(stringArray) {
		if (!stringArray[0] || stringArray[0] === '?') {
			return '';
		}
		const { formatMessage } = this.props.intl;
		return _.orderBy(stringArray.map(key => (
			{ value: key, label: formatMessage({ id: key }) })), "label", "asc");

	}

	onFilterChange(key, value) {
		this.setState({ [key]: value });
	}

	handleChange(key, e) {
		e.preventDefault();
		this.setState({
			[key]: e.target.value
		})
	}

	searchToQueryString() {
		var {
			companyName,
			textSearch,
			serviceLanguage,
			location,
			expertName,
			industries,
			companySize,
			countries,
			services,
		} = this.state;

		var compSize = this.compSizeFromArray(companySize);
		var string = '?';

		if (companyName.length > 0) {
			string += 'companyName=' + companyName + '&';
		}
		if (textSearch.length > 0) {
			string += 'textSearch=' + textSearch + '&';
		}
		if (serviceLanguage.length > 0) {
			string += 'serviceLanguage=' + serviceLanguage + '&';
		}
		if (location.length > 0) {
			string += 'location=' + _.map(location, 'value').join('&') + '&';
		}

		if (expertName.length > 0) {
			string += 'expertName=' + expertName + '&';
		}

		if (industries.length > 0) {
			string += 'industries=' + _.map(industries, 'value').join('&') + '&';
		}

		if (compSize) {
			string += 'companySize=' + compSize.value + '&';
		}

		if (countries.length > 0) {
			string += 'countries=' + _.map(countries, 'value').join('&') + '&';
		}

		if (services.length > 0) {
			string += 'services=' + _.map(services, 'value').join('&');
		}

		if (string.charAt(string.length - 1) === '&') {
			string = string.slice(0, -1);
		}

		return string;
	}

	filterResults() {
		var exact = this.state.results;

		if (this.state.countries && this.state.countries.length > 0) {
			exact = exact.filter(result => (this.filterResult(result.countries, this.state.countries)));

		}
		if (this.state.services && this.state.services.length > 0) {
			exact = exact.filter(result => (this.filterResult(result.services, this.state.services)));
		}
		if (this.state.industries && this.state.industries.length > 0) {
			exact = exact.filter(result => (this.filterResult(result.industries, this.state.industries)));
		}
		if (this.state.location && this.state.location.length > 0) {
			exact = exact.filter(result => (this.filterResult([result.country], this.state.location)));
		}

		return exact;
	}

	filterResult(resultArray, filterArray) {
		var resultOk = false;

		filterArray.map(option => {
			if (resultArray.indexOf(option.value) > -1) {
				resultOk = true;
			}
		});

		return resultOk;
	}

	filterResultText(resultText, filterText) {
		var resultOK = false;

		if (resultText.includes(filterText)) {
			resultOK = true;
		}

		return resultOK;
	}

	filterByTargetCountriesAlone(resultsToFilterOut, results) {
		var idList = resultsToFilterOut.map(result => { return result.id; });

		var countryMatchesList = [];

		if (this.state.countries && this.state.countries.length > 0) {
			countryMatchesList = results.filter(result => (
				idList.indexOf(result.id) === -1
			));

			countryMatchesList = countryMatchesList.filter(result => (this.filterResult(result.countries, this.state.countries)));
		}

		return countryMatchesList;
	}

	compSizeFromArray(companySize) {
		var notArray;
		if (Array.isArray(companySize)) {
			notArray = companySize[0];
			return notArray;
		} else {
			return companySize;
		}
	}

	render() {
		var {
			companyName,
			textSearch,
			serviceLanguage,
			location,
			expertName,
			industries,
			companySize,
			countries,
			services,
			results,
			teamFinland,
			error,
			loading,
			queryString
		} = this.state;

		var compSize = this.compSizeFromArray(companySize);
		var parsedServices = this.parseOptions(servicesList);
		var parsedCountries = this.parseOptions(countriesList);
		var parsedIndustries = this.parseOptions(industriesList);
		var exactResults = this.filterResults(results);
		var targetCountryResults = this.filterByTargetCountriesAlone(exactResults, results);
		var teamFinlandFiltered = this.filterByTargetCountriesAlone(exactResults, teamFinland);

		return (
			<Container fluid className='text-center mx-0 px-0 mt-4'>

				{
					<div>
						<h2 className='text-uppercase'> <FormattedMessage id='searchPageHeader' /> </h2>
						<SearchFilters
							onFilterChange={this.onFilterChange.bind(this)}
							handleChange={this.handleChange.bind(this)}
							companyName={companyName}
							textSearch={textSearch}
							serviceLanguage={serviceLanguage}
							expertName={expertName}
							industries={industries}
							companySize={compSize}
							countries={countries}
							services={services}
							location={location}
							parsedCountries={parsedCountries}
							companySizeOptions={companySizeOptions}
							parsedServices={parsedServices}
							handleSearch={this.handleSearch.bind(this)}
							parsedIndustries={parsedIndustries} />
						<Button block color='primary' disabled={this.state.loading} onClick={this.handleSearch.bind(this)} >
							{this.state.loading
								? <FormattedMessage id='loading' />
								: <FormattedMessage id='search' />
							}
						</Button>
					</div>
				}

				{!loading && error
					? <div className='text-danger p-5 bg-faded'>
						<FormattedMessageHOC {...{id: error}} />
					</div>
					: null
				}

				{loading
					? <FormattedMessage id='loading' />
					: null
				}

				{!loading && !error
					? <div>

						<SearchResults
							countries={countries}
							services={services}
							industries={industries}
							location={location}
							parsedCountries={parsedCountries}
							parsedServices={parsedServices}
							parsedIndustries={parsedIndustries}
							results={exactResults}
							expertName={expertName}
							otherResults={targetCountryResults}
							teamFinland={teamFinlandFiltered}
							queryString={queryString}
							textSearch={textSearch}
							serviceLanguage={serviceLanguage}
							companyName={companyName} />
					</div>
					: null
				}

			</Container>
		)
	}

}

export default _.flowRight(injectIntl, RouterHOC)(SearchPage);
