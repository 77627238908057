import React from 'react';
import {Alert, Button, Container, FormGroup, FormText, Label, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import Dropzone from 'react-dropzone';
import FormattedMessageHOC from "./FormattedMessageHOC";

class ImageUploadSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = { rejected: false };
		this.dropzone = React.createRef();
	}

	onUploadClick(e) {
		e.preventDefault();
		if (this.dropzone.current) {
			this.dropzone.current.open();
		}
		this.setState({ rejected: false });
		return false;
	}

	handleReject() {
		this.setState({ rejected: true });
	}

	imageValidator(file) {
		console.log(file)
		if (!file.type.startsWith('image')) {
			this.setState({ rejected: true });
			return null;
		}
		if (file.size > 10000001) {
			console.log("image too large");
			this.setState({ rejected: true });
			return null;
		}
	}

	render() {
		const {
			value,
			handleChange,
			sectionName,
			hiddenOnPublic,
			preview,
			helperText,
			optional,
			remove,
		} = this.props;

		const {
			rejected
		} = this.state

		return (
			<FormGroup>
				<Row className='limit-max-width-to-parent'>
					<Label for={sectionName} sm='7' size='sm' className='input-label'> <FormattedMessageHOC {...{id: sectionName}} /> {optional ? <FormattedMessage id='optional' /> : ''} </Label>
					{hiddenOnPublic
						? <FormText color='muted' className='caps'> <FormattedMessage id='hiddenOnPublic' /> </FormText>
						: null}
				</Row>
				<FormText className='bottom-margin-small'>
					<FormattedMessageHOC {...{id: helperText}} />
				</FormText>

				{preview ?
					<Container>
						<img style={{ maxWidth: '300px', maxHeight: '300px' }} className='bottom-margin-small right-margin-small' src={preview} />
						<span className='right-margin-small align-self-start closeicon text-danger' key={'closeiconLogo'} onClick={remove.bind(this)}> (<FormattedMessage id='remove' />) </span>
					</Container>
					: ''}
				{rejected ? <Alert color='danger'><FormattedMessage id='imageError'/></Alert> : ''}
				<br />
				<Button type='button' color='primary' onClick={this.onUploadClick.bind(this)} className='mb-2'>
					<FormattedMessage id='uploadFile' />
				</Button>
				<Dropzone ref={this.dropzone} {...{ accept: { 'image/jpeg': [], 'image/gif': [], 'image/png': [] }, validator: this.imageValidator.bind(this)}} onDrop={handleChange.bind(this, sectionName)}>
					{({getRootProps, getInputProps}) => (
						<section className='dropzone'>
							<div {...getRootProps()}>
								<input {...getInputProps()} name={sectionName} />
								<FormattedMessage id='dragAndDrop' />
							</div>
						</section>
					)}
				</Dropzone>
			</FormGroup>
		);
	}

}

export default ImageUploadSection;