import React from 'react';
import {FormattedMessage} from 'react-intl';

class EditThanksPage extends React.Component {
	constructor(props) {
		super(props);
	}

	render () {
		return (
			<div className='text-center limit-width-and-center py-5 px-2'>
				<div name='form-header' className='bottom-margin'>
					<h1 className='bottom-margin-small'> <FormattedMessage id='generalThanksHeader' /> </h1>
					<div className='text-start bottom-margin'>
						<FormattedMessage id='editThanksLead' /> 
					</div>
				</div>
			</div>
			);
	}

}

export default EditThanksPage;