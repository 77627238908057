import React from 'react';
import {createRoot} from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
//import 'react-select/dist/react-select.css';
import ChangeLanguage from './components/ChangeLanguage';

import '../styles/main.css';

const publicContainer = document.getElementById('public');
if (publicContainer) {
	const root = createRoot(publicContainer);
	root.render(<ChangeLanguage />);
}
