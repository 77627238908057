import React from 'react';
import {FormattedMessage} from 'react-intl';

import PdfUploadSection from './PdfUploadSection';
import PublicationSection from './PublicationSection';

class RelatedMaterialsSection extends React.Component {
	constructor(props) {
		super(props);
	}

	render () {
		const {
			company,
			handleFileChange,
			addPublication,
			removePublication,
			removePdf,
			setSectionValid
		} = this.props;

		return (
			<div name='otherSection' className='bottom-margin'>
				<h5> <FormattedMessage id='relatedMaterials' /> </h5>
				<PublicationSection values={company.publications} add={addPublication.bind(this)} remove={removePublication.bind(this)} setSectionValid={setSectionValid.bind(this)} />
				<PdfUploadSection sectionName='cvs' values={company.cvs} helperText='cvHelperText' handleChange={handleFileChange.bind(this)} optional hiddenOnPublic removePdf={ removePdf.bind(this) } />
				<PdfUploadSection sectionName='references' values={company.references} helperText='referencesHelperText' handleChange={handleFileChange.bind(this)} optional hiddenOnPublic removePdf={ removePdf.bind(this) } />
			</div>
		);
	}

}

export default RelatedMaterialsSection;