import React from 'react';
import {FormattedMessage} from 'react-intl';

import TextInputSection from '../TextInputSection';

class LinksSection extends React.Component {
	constructor(props) {
		super(props);
	}

	render () {
		const {
			company,
			handleChange,
			submitClicked,
		} = this.props;

		return (
			<div name='linksSection' className='bottom-margin'>
				<h5> <FormattedMessage id='links' /> </h5>
				<TextInputSection sectionName='webpage' helperText='urlFormatHelperText' value={company.webpage} submitClicked={submitClicked} handleChange={handleChange.bind(this)} maxLength={1000} placeholder='placeholderWebsite' />
				<TextInputSection sectionName='facebook' helperText='facebookFormatHelperText' value={company.facebook} submitClicked={submitClicked} handleChange={handleChange.bind(this)} maxLength={1000} placeholder='placeholderFacebook' optional />
				<TextInputSection sectionName='linkedin' helperText='linkedinFormatHelperText' value={company.linkedin} submitClicked={submitClicked} handleChange={handleChange.bind(this)} maxLength={1000} placeholder='placeholderLinkedin' optional />
			</div>
		);
	}

}

export default LinksSection;