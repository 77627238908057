import React from 'react';
import {Col, FormFeedback, FormGroup, FormText, Input, Label, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import validator from 'validator';
import FormattedMessageHOC from "./FormattedMessageHOC";

class TextInputSection extends React.Component {
	constructor(props) {
		super(props);

		this.state = { valid: true };
	}

	checkInput(value, sectionName) {
		if (!value) {
			if (this.props.optional) {
				return true;
			} else {
				return false;
			}
		}

		if (sectionName === 'webpage' || sectionName === 'linkedin' || sectionName === 'facebook' || sectionName === 'link') {
			if (validator.isURL(value)) {
				return true;
			} else {
				return false;
			}
		} else if (sectionName === 'personEmail' || sectionName === 'email') {
			if (validator.isEmail(value)) {
				return true;
			} else {
				return false;
			}
		} else if (sectionName === 'businessId' && this.props.country && (this.props.country.value === "FIN")) {
			if (this.checkBusinessId(value)) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}

	catchEnter(area, target) {
		if (target.charCode === 13 && area !== 'textarea') {
			target.preventDefault();
		}
	}

	checkOnSubmit(value, sectionName) {
		var isValid = this.checkInput(value, sectionName);


		if (this.props.submitClicked && isValid) {
			return true;
		} else if (this.props.submitClicked && !isValid) {
			return false;
		} else if (!this.props.submitClicked) {
			return true;
		}

	}

	checkBusinessId(value) {
		if (value.match(/[0-9]{7}\-[0-9]{1}/) && value.length === 9) {
			var stringArray = value.split("");
			var sum = (stringArray[0] * 7) + (stringArray[1] * 9) + (stringArray[2] * 10) + (stringArray[3] * 5) + (stringArray[4] * 8) + (stringArray[5] * 4) + (stringArray[6] * 2);
			var remainder = sum % 11;
			var controlNum = parseInt(stringArray[8]);
			if ((remainder === 0) && controlNum === 0) {
				return true;
			} else if (remainder === 1) {
				return false;
			} else if (11 - remainder === controlNum) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	valueToText(array) {
		var text = array.map((h) => { return h + ': \n'; }).join('');
		return text;
	}


	render() {
		const {
			value,
			handleChange,
			sectionName,
			hiddenOnPublic,
			maxLength,
			placeholder,
			optional,
			area,
			className,
			helperText,
			submitClicked,
			helper
		} = this.props;

		var submitOK = this.checkOnSubmit(value, sectionName);

		return (
			<FormGroup color={submitOK ? '' : 'danger'}>
				<Row className='limit-max-width-to-parent'>
					<Col sm={{ size: 'auto', offset: 0 }}>
						<Label for={sectionName} className='right-margin' size='sm' className='input-label'>
							<FormattedMessageHOC {...{id: sectionName}} /> {optional ? <FormattedMessage id='optional' /> : ''}
						</Label>

					</Col>
					{hiddenOnPublic
						? <FormText color='muted' className='caps'> <FormattedMessage id='hiddenOnPublic' /> </FormText>
						: null}
				</Row>

				{helperText
					? <FormText className='showEnter'><FormattedMessageHOC {...{id: helperText}} /></FormText>
					: <div>{
						(helper && helper.length > 0) ?
							<FormText><FormattedMessage id="descHelper" values={{ list: <ul> {helper.map(h => <li key={h}> {h} </li>)} </ul> }} /></FormText>
							: null
					} </div>}
				{area && maxLength ?
					<FormText className='counter'>{value ?
						<span>{maxLength - value.length} / {maxLength}</span>
						: <span>{maxLength} / {maxLength}</span>}
					</FormText>
					: null}
				{(helper && helper.length > 0) ?
					<div>
						<Input color='warning' invalid={!submitOK} className={className || ''} defaultValue={value || this.valueToText(helper)} type={area ? 'textarea' : 'text'} name={sectionName} id={sectionName} onInput={handleChange.bind(this, sectionName)} maxLength={maxLength} state={submitOK ? '' : 'warning'} onKeyUp={this.catchEnter.bind(this, area)} />
					</div>
					: <FormattedMessageHOC {...{id: placeholder}} >
						{message => <Input color='warning' invalid={!submitOK} className={className || ''} defaultValue={value || ''} type={area ? 'textarea' : 'text'} name={sectionName} id={sectionName} onInput={handleChange.bind(this, sectionName)} maxLength={maxLength} placeholder={message} state={submitOK ? '' : 'warning'} onKeyUp={this.catchEnter.bind(this, area)} />}
					</FormattedMessageHOC>}

				{(helper && helper.length > 0 && !submitOK) ?
					(!value || value.length == 0)?
					<div className="invalid-feedback-new">
						<FormattedMessage id='completeDescriptionWarning' />
					</div>
						: null
				: null
				}

				{submitOK
					? null
					: <FormFeedback>
						{(value && value.length > 0)?
						<FormattedMessage id='generalInputWarning' />  :
						<FormattedMessage id='emptyInputWarning'  />}
					</FormFeedback>
					}


			</FormGroup>
		);
	}

}

export default TextInputSection;