import React from 'react';
import {Button, FormGroup, FormText, Label, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import Dropzone from 'react-dropzone';
import FormattedMessageHOC from "../FormattedMessageHOC";

class PdfUploadSection extends React.Component {
	constructor(props) {
		super(props);
		this.dropzone = React.createRef();
	}

	onUploadClick(e) {
		e.preventDefault();
		if (this.dropzone.current) {
			this.dropzone.current.open();
		}
		return false;
	}

	pdfValidator(file) {
		if (!file.type.startsWith('application/pdf')) {
			this.setState({ rejected: true });
			return null;
		}
	}

	render () {
		const {
			values,
			handleChange,
			sectionName,
			hiddenOnPublic,
			helperText,
			optional,
			removePdf
		} = this.props;

		return (
		   <FormGroup>
		   		<Row className='remove-negative-margin-left limit-max-width-to-parent'>
					<Label for={sectionName} size='sm' className='input-label right-margin'> 
						<FormattedMessageHOC {...{id: helperText}} />
						{ optional ? <FormattedMessage id='optional' /> : null } 
					</Label>
					{ hiddenOnPublic 
						? <FormText color='muted' className='caps'> 
							<FormattedMessage color='muted' className='caps' id='hiddenOnPublic' /> 
						</FormText> 
						: null }
				</Row>
				
				{ values ? values.map((file, index) => (
					<Row className='justify-content-start remove-negative-margin-left limit-max-width-to-parent' key={'filerow' + index}>
						<i className='icon ion-document right-margin-small' key={'fileicon' + index} />
						<FormText className='right-margin-small' key={'filetext' + index}>{ file.name }</FormText> 
						<span className='closeicon text-danger' key={'closeicon' + index} onClick={ removePdf.bind(this, index, file.uid, sectionName) }> (<FormattedMessage id='remove' />) </span>
					</Row>)) 
				: '' }
			   	<br />
			   	<Button type='button' color='primary' onClick={this.onUploadClick.bind(this)} className='mb-2' >
					<FormattedMessage id='uploadFiles' />
				</Button>
			   <Dropzone ref={this.dropzone} {...{ multiple: true, accept: { 'application/pdf': [] }, validator: this.pdfValidator.bind(this)}} onDrop={handleChange.bind(this, sectionName)}>
				   {({getRootProps, getInputProps}) => (
					   <section className='dropzone'>
						   <div {...getRootProps()}>
							   <input {...getInputProps()} name={sectionName} />
							   <FormattedMessage id='dragAndDrop' />
						   </div>
					   </section>
				   )}
			   </Dropzone>
				
			</FormGroup>
			);
	}

}

export default PdfUploadSection;