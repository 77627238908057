import React from 'react';
import {FormattedMessage} from 'react-intl';

import TextInputSection from '../TextInputSection';
import SelectSection from '../SelectSection';
import NumberInputSection from '../NumberInputSection';

class CompanyInformationSection extends React.Component {
	constructor(props) {
		super(props);
	}

	getYearOptions() {
		var currentYear = new Date().getFullYear();
		var oldestCompanyYear = 577;
		var list = [];
		for (var i = currentYear; i >= oldestCompanyYear; i--) {
			list.push({ value: i, label: i });
		}
		return list;
	}

	render() {
		const {
			company,
			handleChange,
			handleSelectChange,
			submitClicked
		} = this.props;

		return (
			<div name='companySection' className='bottom-margin'>
				<h5> <FormattedMessage id='companyInfo' /> </h5>
				<SelectSection sectionName='established' value={company.established} submitClicked={submitClicked} handleChange={handleSelectChange.bind(this)} placeholder='placeholderYear' options={this.getYearOptions()} multiple={false} />
				<SelectSection sectionName='turnover' value={company.turnover} submitClicked={submitClicked} handleChange={handleSelectChange.bind(this)} options={[{ value: '<1 M €', label: '<1 M €' }, { value: '1-5 M €', label: '1-5 M €' }, { value: '>5 M €', label: '>5 M €' }]} multiple={false} placeholder='placeholderTurnover' />
				<NumberInputSection sectionName='employeeCount' value={company.employeeCount} submitClicked={submitClicked} type='number' handleChange={handleChange.bind(this)} placeholder='placeholderEmployee' />
				<TextInputSection sectionName='membership' value={company.membership} submitClicked={submitClicked} hiddenOnPublic={false} handleChange={handleChange.bind(this)} placeholder='placeholderMembership' optional />
			</div>
		);
	}

}

export default CompanyInformationSection;