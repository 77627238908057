const servicesList = ['legal', 'ip', 'hr', 'sales', 'communication', 'marketing', 'finance', 'logistics', 'consulting', 'realEstateAndFacilities', 'marketIntelligence', "digitalSalesAndMarketing", "ecommerce", "internationalizationStrategy", "channelDevelopment", "partnerSearch", "salesTraining", "internationalizationTraining", "tradeFairServices", "translationsAndInterpreting"];

const innoServicesList = ['digiProduct', 'innovationProject', 'agile', 'targetMarket', 'branding', 'development', 'testsAndRes', 'productInno', 'other'];

const industriesList = ["general", "bioeconomy", "building", "consultingAndEngineering", "cleantech", "consumer", "creative", "defence", "education",
  "energy", "fashion", "financeAndInsurance", "food", "health", "ict", "manufacturing", "maritime", "tourism", "transportation", "retail"];

const countriesList = ["AFG", "ALB", "DZA", "ASM", "AND", "AGO", "AIA", "ATA", "ATG", "ARG", "ARM", "ABW", "AUS", "AUT", "AZE", "BHS", "BHR", "BGD", "BRB", "BLR", "BEL", "BLZ", "BEN", "BMU", "BTN", "BOL", "BES", "BIH", "BWA", "BVT", "BRA", "IOT", "UMI", "VGB", "VIR", "BRN", "BGR", "BFA", "BDI", "KHM", "CMR", "CAN", "CPV", "CYM", "CAF", "TCD", "CHL", "CHN", "CXR", "CCK", "COL", "COM", "COG", "COD", "COK", "CRI", "HRV", "CUB", "CUW", "CYP", "CZE", "DNK", "DJI", "DMA", "DOM", "ECU", "EGY", "SLV", "GNQ", "ERI", "EST", "ETH", "FLK", "FRO", "FJI", "FIN", "FRA", "GUF", "PYF", "ATF", "GAB", "GMB", "GEO", "DEU", "GHA", "GIB", "GRC", "GRL", "GRD", "GLP", "GUM", "GTM", "GGY", "GIN", "GNB", "GUY", "HTI", "HMD", "VAT", "HND", "HKG", "HUN", "ISL", "IND", "IDN", "CIV", "IRN", "IRQ", "IRL", "IMN", "ISR", "ITA", "JAM", "JPN", "JEY", "JOR", "KAZ", "KEN", "KIR", "KWT", "KGZ", "LAO", "LVA", "LBN", "LSO", "LBR", "LBY", "LIE", "LTU", "LUX", "MAC", "MKD", "MDG", "MWI", "MYS", "MDV", "MLI", "MLT", "MHL", "MTQ", "MRT", "MUS", "MYT", "MEX", "FSM", "MDA", "MCO", "MNG", "MNE", "MSR", "MAR", "MOZ", "MMR", "NAM", "NRU", "NPL", "NLD", "NCL", "NZL", "NIC", "NER", "NGA", "NIU", "NFK", "PRK", "MNP", "NOR", "OMN", "PAK", "PLW", "PSE", "PAN", "PNG", "PRY", "PER", "PHL", "PCN", "POL", "PRT", "PRI", "QAT", "KOS", "REU", "ROU", "RUS", "RWA", "BLM", "SHN", "KNA", "LCA", "MAF", "SPM", "VCT", "WSM", "SMR", "STP", "SAU", "SEN", "SRB", "SYC", "SLE", "SGP", "SXM", "SVK", "SVN", "SLB", "SOM", "ZAF", "SGS", "KOR", "SSD", "ESP", "LKA", "SDN", "SUR", "SJM", "SWZ", "SWE", "CHE", "SYR", "TWN", "TJK", "TZA", "THA", "TLS", "TGO", "TKL", "TON", "TTO", "TUN", "TUR", "TKM", "TCA", "TUV", "UGA", "UKR", "ARE", "GBR", "USA", "URY", "UZB", "VUT", "VEN", "VNM", "WLF", "ESH", "YEM", "ZMB", "ZWE", "CS1"]

const turnoverOptions = [
  { value: '<1 M €', label: '<1 M €' },
  { value: '1-5 M €', label: '1-5 M €' },
  { value: '>5 M €', label: '>5 M €' }
];

const companySizeOptions = [
  { value: '1', label: '< 10', sizeStart: 0, sizeEnd: 9 },
  { value: '2', label: '10 .. 50', sizeStart: 10, sizeEnd: 50 },
  { value: '3', label: '50 .. 250', sizeStart: 50, sizeEnd: 250 },
  { value: '4', label: '250 .. 1000', sizeStart: 250, sizeEnd: 1000 },
  { value: '5', label: '> 1000', sizeStart: 1001, sizeEnd: 10000000 }
];

export { servicesList, innoServicesList, industriesList, turnoverOptions, companySizeOptions, countriesList };


