import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, Col, Collapse, Row} from 'reactstrap';
import HttpRequestUtil from '../../util/HttpRequestUtil';
import {base64StringToBlob} from 'blob-util';
import logoPlaceholder from '../../../images/logo-placeholder.svg';
import logoBF from '../../../images/bf_logo_blue_rgb.png';
import logoFormin from '../../../images/Su-Ru_CMYK.png';
import ContactRequestSection from './ContactRequestSection';
import StarRating from "../StarRating";
import FormattedMessageHOC from "../FormattedMessageHOC";
import _ from "lodash";
import {RouterHOC} from "../RouterHOC";

class ViewCompanyPage extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { company: {}, loading: false, logo: '', user: {}, contactPersonPhotos: [], lastSearchQuery: '', collapse: false };
    }

    componentDidMount() {
        //  this.getCountries();
        this.refreshData();
        this.setState({ lastSearchQuery: this.props.location.search });
        this.resultStart.scrollIntoView({ behavior: "smooth" });

        if (localStorage.getItem('lng') === 'en') {
            document.title = 'Profile'
        } else { document.title = "Profiili" }
    }

    refreshData() {
        var uid = this.props.params.id;
        this.getCompany(uid);
        this.getCompanyUser(uid);
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    getCompany(uid) {
        this.setState({ loading: true });
        HttpRequestUtil.get('./api/company/' + uid, this.setCompany.bind(this), this.setError.bind(this));
        // HttpRequestUtil.get('../../i18n/mockdata.json', this.setCompany.bind(this), this.setError.bind(this));
    }

    getCompanyUser(uid) {
        this.setState({ loading: true });
        HttpRequestUtil.get('./api/company/' + uid + "/user", this.setCompanyUser.bind(this), this.setError.bind(this));
    }

    setCompany(data) {
        this.setState({ company: data[0], evaluated: false, loading: false });
        this.parseLogoImg(data[0].logo);
        this.parseContactPersonImg(data[0]);
    }

    setCompanyUser(data) {
        this.setState({ user: data[0] });
    }

    parseLogoImg(baseImg) {
        if (!baseImg) { return; }
        this.setState({ logo: URL.createObjectURL(base64StringToBlob(baseImg)) })
    }

    parseContactPersonImg(company) {
        if (!company || !company.experts || company.experts.length === 0) { return; }

        company.experts.map((expert) => {
            const baseImg = expert.photo;
            if (baseImg) {
                const blobUrl = URL.createObjectURL(base64StringToBlob(baseImg));
                let photos = this.state.contactPersonPhotos.slice();
                photos.push(blobUrl)
                this.setState({ contactPersonPhotos: photos });
            }
        });
    }

    setError() {
        this.setState({ loading: false });
    }

    getCountryName(name) {
        return (<FormattedMessageHOC {...{id: name}} />);
    }

    renderSelections(selections) {
        if (!selections || selections.length === 0) {
            return;
        }

        return (<div className='mb-3'>
            {selections.map((item, i) => {
                if (item.length > 0) {
                    return (<span key={i}>
                        <FormattedMessageHOC {...{id: item}} />
                        {i < (selections.length - 1) ? ', ' : ''}
                    </span>);
                }
                else { return null; }
            }
            )}
        </div>);
    }

    renderContactPersons(experts) {
        if (!experts || experts.length === 0) {
            return;
        }

        var photos = this.state.contactPersonPhotos;

        return (<div>
            {experts.map((expert, i) => {
                return (<Row className="person" key={i}>

                    {photos.length > 0 ? <div className='photo-div' >
                        <img className="person-photo-img person-photo" src={photos[i]} />
                    </div> : null}

                    <div className={(photos.length > 0) ? "person-info" : "person-info-no-photo"}>
                        <span className="bold">{expert.name}</span>
                        <div>
                            {expert.title ? <span> {expert.title}<br /></span> : null}
                            {expert.phone}<br />
                            {expert.email}<br />
                            {expert.link
                                ? <a href={expert.link} target="_blank"><FormattedMessage id='link' /></a>
                                : null
                            }
                        </div>
                    </div>
                </Row>);
            }
            )}
        </div>);
    }

    returnToResults(e) {
        e.preventDefault();
        if (this.state.company.inno) {
            this.props.navigate('/searchInno/' + this.state.lastSearchQuery);
        } else {
            this.props.navigate('/search/' + this.state.lastSearchQuery);
        }
    }

    render() {
        var {
            company,
            logo
        } = this.state;

        if (this.state.loading) {
            return (
                <div className="mt-4">
                    <div className='text-center px-2 limit-width-and-center'>
                        <div name='form-header' className='bottom-margin'>
                            <h1 className='bottom-margin-small caps'> <FormattedMessage id='loading' /> </h1>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="mt-4">
                <div style={{ float: "left", clear: "both" }} ref={(scrollertop) => { this.resultStart = scrollertop; }}></div>
                <Button onClick={this.returnToResults.bind(this)} className="backButton">
                    <FormattedMessage id='backToResults' />
                </Button>
                <div className='text-center limit-width-and-center'>
                    <div name='form-header' className='m-4'>
                        <Row className='justify-content-center'>
                            <Col>
                                {
                                    logo
                                        ? <img style={{ maxHeight: '300px', maxWidth: '220px' }} src={logo} className='pb-3' />
                                        : (company.teamFinland
                                            ? (company.user.email.indexOf('@businessfinland.fi') > -1)
                                                ? <img style={{ maxHeight: '300px', maxWidth: '220px' }} src={logoBF} />
                                                : (company.user.email.indexOf('@formin.fi') > -1)
                                                    ? <img style={{ maxHeight: '300px', maxWidth: '220px' }} src={logoFormin} />
                                                    : <img src={logoPlaceholder} alt='logo-placeholder' style={{ maxHeight: '300px', maxWidth: '220px' }} />
                                            : <img src={logoPlaceholder} alt='logo-placeholder' style={{ maxHeight: '300px', maxWidth: '220px' }} />
                                        )
                                }
                            </Col>
                            <Col className='text-start ml-3 text-uppercase'>
                                <h1 className={company.unit ? '' : 'bottom-margin-small'}> {company.name} </h1>
                                {company.unit ? <h5 className='bottom-margin-small'>{company.unit}</h5> : null}
                                {company.country ? <p> {company.city}, {this.getCountryName(company.country)}</p> : null}
                            </Col>
                        </Row>
                    </div>

                    <div className='text-start m-4'>
                        <div className='showEnter'><p className='mb-4'>{company.description}</p></div>
                        <Row>
                            <Col sm={8} className="bottom-margin">
                                <h6 className="bottom-margin-small caps"><FormattedMessage id='servicesHeader' /></h6>
                                <span className="bold"><FormattedMessage id='services' /></span>
                                {this.renderSelections(company.services)}
                                <div className='showEnter'> <p className='mb-4'>{company.descriptionServices}</p> </div>
                                {(company.industries || company.descriptionIndustries || company.serviceLanguage || company.countries || company.descriptionCountries)
                                    ? <div>
                                        {((company.industries && company.industries.length > 0) || company.descriptionIndustries)
                                            ? <div><span className="bold"><FormattedMessage id='industries' /></span>
                                                {this.renderSelections(company.industries)}
                                                <div className='showEnter'><p className='mb-4'>{company.descriptionIndustries}</p></div></div>
                                            : null}
                                        {((company.countries && company.countries.length > 0 && company.countries[0] !== "") || company.descriptionCountries)
                                            ? <div> <span className="bold"><FormattedMessage id='countries' /></span>
                                                {this.renderSelections(company.countries)}
                                                <div className='showEnter'><p className='mb-4'>{company.descriptionCountries}</p> </div></div>
                                            : null}
                                        {company.serviceLanguage
                                            ? <div><span className="bold"><FormattedMessage id='serviceLanguage' /></span>
                                                <div className="bottom-margin-small mb-5">{company.serviceLanguage}</div> </div>
                                            : null}
                                    </div> : null}
                                {(company.established || company.turnover || company.employeeCount)
                                    ? <div><h6 className="bottom-margin-small caps"><FormattedMessage id='financialInformation' /></h6>
                                        {company.established
                                            ? <div><span className="bold"><FormattedMessage id='established' /></span>
                                                <div className='mb-3'>{company.established}</div> </div>
                                            : null}
                                        {company.turnover
                                            ? <div> <span className="bold"><FormattedMessage id='turnover' /></span>
                                                <div className='mb-3'>{company.turnover}</div> </div>
                                            : null}
                                        {company.employeeCount
                                            ? <div> <span className="bold"><FormattedMessage id='employeeCount' /></span>
                                                <div className='mb-3'>{company.employeeCount}</div></div>
                                            : null}
                                    </div>
                                    : null}

                            </Col>

                            <Col sm={4} className="bottom-margin">
                                {company.businessId
                                    ? <div><h6 className="bottom-margin-small caps"><FormattedMessage id="businessId" /></h6>
                                        <div className="bottom-margin-small mb-5">{company.businessId}</div></div>
                                    : null}
                                {company.address
                                    ? <div><h6 className="bottom-margin-small caps"><FormattedMessage id='addressHeader' /></h6>
                                        <div className="bottom-margin-small mb-5">{company.address}</div> </div>
                                    : null}
                                {(company.webpage || company.facebook || company.linkedin)
                                    ? <div>
                                        <h6 className="bottom-margin-small caps"><FormattedMessage id='links' /></h6>
                                        <div className="bottom-margin-small mb-5">
                                            {company.webpage
                                                ? <span>
                                                    <a href={company.webpage} target="_blank">
                                                        <FormattedMessage id='webpage' />
                                                    </a>
                                                    <br />
                                                </span>
                                                : null}
                                            {company.facebook
                                                ? <span>
                                                    <a href={company.facebook} target="_blank">
                                                        <FormattedMessage id='facebook' />
                                                    </a>
                                                    <br />
                                                </span>
                                                : null}
                                            {company.linkedin
                                                ? <span>
                                                    <a href={company.linkedin} target="_blank">
                                                        <FormattedMessage id='linkedin' />
                                                    </a>
                                                </span>
                                                : null}
                                        </div>
                                    </div>
                                    : null}

                                <h6 className="bottom-margin-small caps"><FormattedMessage id='contactPerson' /></h6>
                                {this.renderContactPersons(company.experts)} <br />
                            </Col>
                        </Row>
                    </div>
                </div>
                <Button onClick={this.returnToResults.bind(this)} className="backButton">
                    <FormattedMessage id='backToResults' />
                </Button>
                <div className="contact-area">
                    <ContactRequestSection company={company} />
                </div>
            </div>
        )
    }

}

export default _.flowRight(injectIntl, RouterHOC)(ViewCompanyPage);
