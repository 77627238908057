import React from 'react';
import {Col, FormFeedback, FormGroup, FormText, Input, Label, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import FormattedMessageHOC from "./FormattedMessageHOC";

class NumberInputSection extends React.Component {
	constructor(props) {
		super(props);
	}

	onChange(e) {
		var value = e.target.value;
		if (value >= 0) {
			this.props.handleChange(this.props.sectionName, e);
		}
	}

	checkInput(value, sectionName) {
		if (!value) {
			if (this.props.optional) {
				return true;
			} else {
				return false;
			}
		}
		return true;
	}

	checkOnSubmit(value, sectionName) {
		var isValid = this.checkInput(value, sectionName);

		if (this.props.submitClicked && isValid) {
			return true;
		} else if (this.props.submitClicked && !isValid) {
			return false;
		} else if (!this.props.submitClicked) {
			return true;
		}

	}

	render() {
		const {
			value,
			handleChange,
			sectionName,
			hiddenOnPublic,
			placeholder
		} = this.props;
		var submitOK = this.checkOnSubmit(value, sectionName);

		return (
			<FormGroup color={submitOK ? '' : 'danger'}>
				<Row className='limit-max-width-to-parent'>
					<Col sm='7'>
						<Label for={sectionName} size='sm' className='input-label'> <FormattedMessageHOC {...{id: sectionName}} /> </Label>
					</Col>
					{hiddenOnPublic
						? <FormText color='muted' className='caps'> <FormattedMessage id='hiddenOnPublic' /> </FormText>
						: null}
				</Row>

				<FormattedMessageHOC {...{id: placeholder}}>
					{message => <Input invalid={!submitOK} value={value || ''} name={sectionName} id={sectionName} onInput={this.onChange.bind(this)} placeholder={message} />}
				</FormattedMessageHOC>
				{submitOK
					? null
					: <FormFeedback>
						{(value && value.length > 0) ?
							<FormattedMessage id='generalInputWarning'/> :
							<FormattedMessage id='emptyInputWarning'/>
						}
				 		</FormFeedback>}

			</FormGroup>
		);
	}

}

export default NumberInputSection;