import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Col, Container, Row} from 'reactstrap';

class FinproTekes extends React.Component {
	constructor(props) {
		super(props);
	}

	render () {
		const { formatMessage } = this.props.intl;

		return (
			<Container fluid className='text-center'>
				<Row className='justify-content-between'>
					<Col md='6' sm='12' xs='12' className='dot-background-dark bg-faded p-5'>
						<h2 className='text-uppercase'>
							<FormattedMessage id='finpro' />
						</h2>
						<p>
							<FormattedMessage id='finproText1' />
						</p>
						<p>
							<FormattedMessage id='finproText2' />
						</p>
						<a href='https://www.businessfinland.fi' className='btn btn-secondary text-uppercase'>
							<FormattedMessage id='finproButton' />
						</a>
					</Col>

					<Col md='6' sm='12' xs='12' className='p-5 dot-background-light'>
						<h2 className='text-uppercase'>
							<FormattedMessage id='tekes' />
						</h2>
						<p>
							<FormattedMessage id='tekesText' />
						</p>
						<a href={formatMessage({id:'tekesURL'})} className='btn btn-secondary text-uppercase' target='_blank'>
							<FormattedMessage id='tekesButton' />
						</a>
					</Col>

				</Row>
			</Container>
		)
	}

}

export default injectIntl(FinproTekes);
