import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Container} from 'reactstrap';

import HttpRequestUtil from '../../util/HttpRequestUtil';
import _ from 'lodash';
import {RouterHOC} from "../RouterHOC";

class SearchInnoPage extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { location } = this.props;
		if (location.search) {
			this.getFilters();
		}

		if (localStorage.getItem('lng') === 'en') {
			document.title = 'Find innovation consultants'
		} else { document.title = "Etsi innovaatiotoiminnan asiantuntijoita" }
	}

	handleSearch(e) {
		e.preventDefault();
		this.setState({
			loading: true,
			queryString: this.searchToQueryString()
		});
		this.props.navigate('/searchInno/' + this.searchToQueryString());

		HttpRequestUtil.post('../api/companies/', JSON.stringify(this.getSearchJson()), this.setResults.bind(this), this.setError.bind(this)); //CHANGE ROUTE?
	}

	getResults() {
		this.setState({ loading: true });
		HttpRequestUtil.post('../api/companies/', JSON.stringify(this.getSearchJson()), this.setResults.bind(this), this.setError.bind(this)); //CHANGE ROUTE?
	}

	getSearchJson() {
		var json = {
			name: this.state.companyName,
			description: this.state.textSearch,
			expertName: this.state.expertName,
			city: this.state.citySearch,
			servicesOffered: _.map(this.state.services, 'value'),
			inno: true,
		};

		return json;
	}

	getFilters() {
		const { location } = this.props;
		if (!location.search) {
			return;
		}

		var filters = new URLSearchParams(location.search.slice(1, location.search.length));
		var services = this.getTextFromUrl(location, "services=");


		this.setState({
			services: Array.isArray(services) ? this.parseOptions(services) : (services ? this.parseOptions([services]) : []),
			companyName: filters.get("companyName") ? filters.get("companyName") : '',
			textSearch: filters.get("textSearch") ? filters.get("textSearch") : '',
			expertName: filters.get("expertName") ? filters.get("expertName") : '',
			citySearch: filters.get("citySearch") ? filters.get("citySearch") : '',
			queryString: location.search
		}, this.getResults);
	}

	getTextFromUrl(location, splitfrom) {
		var searchArray = location.search.split(splitfrom);
		var c = _.last(searchArray);
		if (c.indexOf('=') > -1) {
			var d = _.first(c.split("="));
			var e = _.dropRight(d.split("&"), 1);
		} else {
			var d = _.first(c.split("="));
			var e = d.split("&");
		}
		return e;
	}

	setError(data) {
		this.setState({ error: 'unableToGetResults', loading: false });
	}

	setResults(data) {
		var results = data ? this.arrangeByName(data) : null;
		var teamFinland = data ? this.arrangeByName(data.filter(result => (result.teamFinland))) : null;
		var acceptedOnly = results ? results.filter(result => result.accepted) : null;
		this.setState({ results: acceptedOnly, teamFinland: teamFinland, loading: false, error: null });
	}

	arrangeByName(list) {
		list.sort((company1, company2) => {
			var string1 = company1['name'].toLowerCase();
			var string2 = company2['name'].toLowerCase();
			if (string1 < string2) {
				return -1;
			} else if (string1 > string2) {
				return 1;
			}
			return 0;
		});
		return list;
	}

	parseOptions(stringArray) {
		if (!stringArray[0] || stringArray[0] === '?') {
			return '';
		}
		const { formatMessage } = this.props.intl;
		return _.orderBy(stringArray.map(key => (
			{ value: key, label: formatMessage({ id: key }) })), "label", "asc");

	}

	onFilterChange(key, value) {
		this.setState({ [key]: value });
	}

	handleChange(key, e) {
		e.preventDefault();
		this.setState({
			[key]: e.target.value
		})
	}

	searchToQueryString() {
		var {
			companyName,
			textSearch,
			expertName,
			services,
			citySearch
		} = this.state;

		var string = '?';

		if (companyName.length > 0) {
			string += 'companyName=' + companyName + '&';
		}
		if (textSearch.length > 0) {
			string += 'textSearch=' + textSearch + '&';
		}

		if (expertName.length > 0) {
			string += 'expertName=' + expertName + '&';
		}

		if (citySearch.length > 0) {
			string += 'citySearch=' + citySearch + '&';
		}

		if (services.length > 0) {
			string += 'services=' + _.map(services, 'value').join('&');
		}

		if (string.charAt(string.length - 1) === '&') {
			string = string.slice(0, -1);
		}

		return string;
	}

	filterResults() {
		var exact = this.state.results;
		if (this.state.services && this.state.services.length > 0) {
			exact = exact.filter(result => (this.filterResult(result.services, this.state.services)));
		}
		return exact;
	}

	filterResult(resultArray, filterArray) {
		var resultOk = false;

		filterArray.map(option => {
			if (resultArray.indexOf(option.value) > -1) {
				resultOk = true;
			}
		});

		return resultOk;
	}

	filterByTargetCountriesAlone(resultsToFilterOut, results) {
		var idList = resultsToFilterOut.map(result => { return result.id; });

		var countryMatchesList = [];

		if (this.state.countries && this.state.countries.length > 0) {
			countryMatchesList = results.filter(result => (
				idList.indexOf(result.id) === -1
			));

			countryMatchesList = countryMatchesList.filter(result => (this.filterResult(result.countries, this.state.countries)));
		}

		return countryMatchesList;
	}


	render() {

		return (
			<Container fluid className='text-center mx-0 px-0 mt-4'>

				{
					<div>
						<p> <FormattedMessage id='pageNotAvailable' /> </p>
					</div>
				}

			</Container>
		)
	}

}

export default _.flowRight(injectIntl, RouterHOC)(SearchInnoPage);
