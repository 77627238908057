import React from 'react';
import {Container} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import pdfFI from '../../files/BF_Expert_Search_guide_2018.pdf';
import pdfEN from '../../files/BF_Expert_Search_guide_2018_english.pdf';

class HowToExpertPage extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount(){
		if (localStorage.getItem('lng') === 'en') {
			document.title = 'How to find an expert'
			} else { document.title = "Kuinka valitsen asiantuntijan" }
	}

	render () {
		return (
			<Container>
				<div className='text-center limit-width-and-center py-5 px-2'>
					<div name='form-header' className='bottom-margin'>
						<h1 className='bottom-margin-small'> <FormattedMessage id='howToExpertHeader' /> </h1>
						<div className='text-start bottom-margin' style={{fontSize: '20px'}}>
							<FormattedMessage id='howToExpertLead' /> 
						</div>
					</div>

					<div className='text-start bottom-margin'>
						<p><FormattedMessage id='howToExpertP1' /></p>
						<p><FormattedMessage id='howToExpertP2' /></p>
						<p>
							<FormattedMessage id='howToExpertP3' /> </p>
						<p style={{fontWeight: "bold"}}><FormattedMessage id="howtoLinks" values={{
						link1: <a target='_blank' href={pdfFI}><FormattedMessage id="finnishLink" /></a>,
						link2: <a target='_blank' href={pdfEN}><FormattedMessage id="englishLink" /></a>
				        }} />
						</p>
					</div>

				</div>
			</Container>
			);
	}

}

export default HowToExpertPage;