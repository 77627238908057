import React from 'react';
import {Button, Col, FormFeedback, FormGroup, FormText, Input, Label, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import validator from 'validator';

class PublicationSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {currentPub: '', valid: true};
	}

	handleChange(e) {
		this.setState({currentPub: e.target.value, valid: true});
	}

	onAddClick(callback, e) {
		e.preventDefault();

		var value = this.state.currentPub;

		if (validator.isURL(value)) {
			callback(value, e);
			this.setState({currentPub: '', valid: true});
		}
	}

	checkInput() {
		const { setSectionValid } = this.props;

		let validity;
		if (!this.state.currentPub || this.state.currentPub === '') {
			validity = true;
			this.setState({valid: true});
			setSectionValid(validity);
			return;
		}

		if (validator.isURL(this.state.currentPub)) {
			validity = true;
			this.setState({valid: true});
		} else {
			validity = false;
			this.setState({valid: false});
		}
		setSectionValid(validity);
	}

	render () {
		const {
			values,
			add,
			remove
		} = this.props;

		const { valid } = this.state;

		return (
		   <FormGroup color={valid ? '' : 'warning'}>
				<Row className='limit-max-width-to-parent'>
					<Col sm={{ size: 'auto', offset: 0}}>
						<Label for='publications' className='right-margin' size='sm' className='input-label'> 
							<FormattedMessage id='publications' />
							<FormattedMessage id='optional' /> 
						</Label>
					</Col>
				</Row>

				{ values ? values.map((url, index) => (
					<Row className='justify-content-start remove-negative-margin-left' key={'urlrow' + index}>
						<FormText className='right-margin' key={'urltext' + index}>{ url }</FormText>
						<span className='closeicon text-danger' key={'closeicon' + index} onClick={ remove.bind(this, index) }> (<FormattedMessage id='remove' />) </span>
					</Row>)) 
				: '' }

				<Row className='limit-max-width-to-parent'>
					<Col sm='9'>
						<FormattedMessage id='placeholderPublications'>
							{message => <Input invalid={!valid} color='warning' value={this.state.currentPub} type='text' name='publications' id='publications' onChange={this.handleChange.bind(this)} placeholder={message} state={this.state.valid ? '' : 'warning'} onBlur={this.checkInput.bind(this)} /> }
						</FormattedMessage>
						<FormFeedback> <FormattedMessage id='generalInputWarning'/> </FormFeedback>
					</Col>
					<Col sm='3'>
						<Button block disabled={!this.state.currentPub} color='primary' onClick={ this.onAddClick.bind(this, add) }> <FormattedMessage id='addAnother' /></Button>
					</Col>

				</Row>

			</FormGroup>
			);
	}

}

export default PublicationSection;