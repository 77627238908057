const parseCompanyData = rawCompany => {
	var company = Object.assign({}, rawCompany);

	parseInputs(company);

	return company;
}

const parseInputs = company => {
	company.experts = removeEmptyExperts(company.experts);
	parseSelects(company);
	parseUrls(company);
}

const removeEmptyExperts = experts => {
	if (!experts || experts.length === 0) {
		return null;
	}

	var newExperts = experts.filter(expert => (
		(expert.name && expert.phone && expert.email)));

	return newExperts;
}

const parseSelects = company => {
	company.country = company.country ? getValueString(company.country) : null;
	company.established = company.established ? getValueString(company.established) : null;
	company.turnover = company.turnover ? getValueString(company.turnover) : null;
	company.services = company.services ? company.services.map(service => (getValueString(service))) : null;
	company.countries = company.countries ? company.countries.map(country => (getValueString(country))) : null;
	company.industries = company.industries ? company.industries.map(industry => (getValueString(industry))) : null;
}

const getValueString = item => {
	var value = item.value ? item.value : item;
	return value;
}

const parseUrls = company => {
	if (company.webpage) {
		company.webpage = (company.webpage.indexOf('://') === -1) ? 'http://' + company.webpage : company.webpage;
	}
	if (company.linkedin) {
		company.linkedin = (company.linkedin.indexOf('://') === -1) ? 'http://' + company.linkedin : company.linkedin;
	}
	if (company.facebook) {
		company.facebook = (company.facebook.indexOf('://') === -1) ? 'http://' + company.facebook : company.facebook;
	}

	company.experts = company.experts.map(expert => {
		if (expert.link) {
			expert.link = (expert.link.indexOf('://') === -1) ? 'http://' + expert.link : expert.link;
		}
		return expert;
	});

	if (company.publications) {
		company.publications = company.publications.map(pub => {
			return (pub.indexOf('://') === -1) ? 'http://' + pub : pub;
		});
	}
}


const parseFileToUrl = (file, key, callback) => {
	var reader = new FileReader();
	reader.onload = event => {
		var contents = event.target.result;
		contents = contents.substring(contents.indexOf(',') + 1);
		callback(contents, key);
	};
	reader.readAsDataURL(file);
}

const parseFileToObject = (file, key, callback) => {
	var reader = new FileReader();
	reader.onload = event => {
		var contents = event.target.result;
		contents = contents.substring(contents.indexOf(',') + 1);
		callback({ file: contents, name: file.name }, key);
	};
	reader.readAsDataURL(file);
}

export { parseCompanyData, parseFileToUrl, parseFileToObject };