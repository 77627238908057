import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Card, CardBody, CardSubtitle, CardTitle, Col} from 'reactstrap';
import star from '../../../images/star-icon.svg';
import StarRating from "../StarRating";
import {RouterHOC} from "../RouterHOC";


class SearchResultCard extends React.Component {
	constructor(props) {
		super(props);
	}

	getLabel(key, parsedArray) {
		if (!parsedArray || parsedArray.length === 0) {
			return null;
		}
		var match = parsedArray.find(parsed => (parsed.value === key));
		return match ? match.label : '';
	}

	goToProfile(uid) {
		this.props.navigate('/profile/' + uid + this.props.queryString);
	}

	getFirstCharactersOfOptions(list, maxCharacters, parsedOptions) {
		var listAsString = '';

		for (var i = 0; i < list.length; i++) {
			listAsString += this.getLabel(list[i], parsedOptions);

			if (listAsString.length > maxCharacters) {
				listAsString += '...';
				break;
			} else if (i < list.length - 1) {
				listAsString += ', ';
			}
		}

		return listAsString;
	}

	getDescription(description, maxCharacters) {
		var cutDescription = description.substring(0, maxCharacters)
		return cutDescription + '...';
	}

	render() {
		var {
			index,
			result,
			logo,
			parsedCountries,
			parsedServices,
			parsedIndustries
		} = this.props;

		return (
			<Col xl='4' lg='4' md='6' sm='12' xs='12' className='p-3' key={index}>
				<Card className='p-3 expertcard m-0' onClick={this.goToProfile.bind(this, result.uid)}>

					{/* {logo} */}

					<CardBody style={{ overflow: "hidden" }}>
						<CardTitle className='text-uppercase expertcard-title'> {result.name} </CardTitle>
						<CardSubtitle className='text-uppercase expertcard-subtitle'> {result.city + ', ' + this.getLabel(result.country, parsedCountries)} </CardSubtitle>

						<hr className='mt-3 mb-3 divider border-2' />

						<p className='text-uppercase my-1 expertcard-list-header'>
							<FormattedMessage id='serviceOffered' />
						</p>
						<p className='expertcard-list-text mt-1 mb-2'>
							{this.getFirstCharactersOfOptions(result.services, 60, parsedServices)}
						</p>

						<p className='text-uppercase expertcard-list-header my-1'>
							<FormattedMessage id='industry' />
						</p>
						<p className='expertcard-list-text mt-1 mb-2'>
							{this.getFirstCharactersOfOptions(result.industries, 60, parsedIndustries)}
						</p>
						<p className='text-uppercase expertcard-list-header my-1'>
							<FormattedMessage id='description' />
						</p>
						<p className='expertcard-list-text mt-1 mb-2'>
							{this.getDescription(result.description, 170)}
						</p>


					</CardBody>
				</Card>
			</Col>
		)
	}

}

export default RouterHOC(SearchResultCard);