import React from 'react';
import {Container} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import ContactUs from './ContactUs.jsx';

class AboutPage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        if (localStorage.getItem('lng') === 'en') {
            document.title = 'About'
            } else { document.title = "Lisätietoa" }
    }

    render () {
        return (
            <Container>
                <div className='text-center limit-width-and-center py-5 px-2'>
                    <div name='form-header' className='bottom-margin'>
                        <h1 className='bottom-margin-small'> <FormattedMessage id='about' /> </h1>
                        <div className='text-start bottom-margin' style={{fontSize: '20px'}}>
                            <FormattedMessage id='aboutLead' /> 
                        </div>
                    </div>
                    <div className='text-start bottom-margin'>
                        <h2><FormattedMessage id='aboutSubHeader1' /></h2>
                        <p><FormattedMessage id='aboutP1' /></p>
                        <h2><FormattedMessage id='aboutSubHeader2' /></h2>
                        <p><FormattedMessage id='aboutP4' /></p>
                        <p><FormattedMessage id='aboutP5' /></p>
                        <p><FormattedMessage id='aboutP6' /></p>
                    </div>
                </div>
                <ContactUs />
            </Container>
            );
    }
}
export default AboutPage;