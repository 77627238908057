import React from 'react';
import {Button, Card, CardBody, Col, FormGroup, Popover, PopoverBody, PopoverHeader} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import {parseFileToUrl} from '../../util/CompanyDataParser';
import TextInputSection from '../TextInputSection';
import ImageUploadSection from '../ImageUploadSection';
import {base64StringToBlob} from "blob-util";

class ContactInput extends React.Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.state = {index: this.props.index, photo: '', popoverOpen: false};
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.photo) {
			this.parseImage(nextProps.photo);
		}
	}

	toggle() {
		this.setState({
			popoverOpen: !this.state.popoverOpen
		});
	}

	parseImage(baseImg) {
		if (!baseImg) {
			return;
		}
		this.setState({ photo: URL.createObjectURL(base64StringToBlob(baseImg)) });
	}

	toggle() {
		this.setState({
			popoverOpen: !this.state.popoverOpen
		});
	}

	removePhoto() {
		this.setState({ photo: '' });
		this.props.removePhoto(this.state.index);
	}

	handleChange(key, e) {
		e.preventDefault();

		if (key === 'personName') {
			key = 'name';
		}
		if (key === 'personEmail') {
			key = 'email';
		}

		this.props.setExpertInfo(this.state.index, key, e.target.value);
	}

	getPreview(imgFile) {
		var reader = new FileReader();

		reader.addEventListener('load', () => {
			this.setState({ photo: reader.result });
		}, false);

		reader.readAsDataURL(imgFile);
	}

	handleFileChange(key, accepted, rejected) {
		var file = accepted[0];
		if (accepted.length > 0) {
			this.getPreview(file);
			parseFileToUrl(file, key, this.setPhotoParseResults.bind(this));
		}
	}

	setPhotoParseResults(fileContents, key) {
		this.props.setExpertInfo(this.state.index, key, fileContents);
	}

	handleDelete(e) {
		e.preventDefault();
		this.props.removeExpertInfo(this.state.index);
		this.setState({ popoverOpen: false });
	}

	render() {
		var {
			name,
			email,
			title,
			phone,
			link,
			information,
			photo,
			index,
			submitClicked,
			allExperts
		} = this.props;

		return (
			<Card>
				<CardBody>
					<FormGroup row className='limit-max-width-to-parent'>
						<Col sm='9' />
						{allExperts > 1
							? <Col sm='3'><Button className="mr-1" outline color="danger" id={'Popover-' + this.props.index} onClick={this.toggle}>
								<FormattedMessage id='deleteExpert' />
							</Button>
								<Popover isOpen={this.state.popoverOpen} target={'Popover-' + this.props.index} toggle={this.toggle}>
									<PopoverHeader>	<FormattedMessage id='verificationTitle' /></PopoverHeader>	
									<PopoverBody>
										<FormattedMessage id='expertVerificationDescription' />
										<br />
										<Button style={{ width: '100%' }} color='danger' onClick={this.handleDelete.bind(this)}> <FormattedMessage id='delete' /> </Button>
									</PopoverBody>
								</Popover></Col>
							: null}
					</FormGroup>

					<TextInputSection sectionName='personName' value={name} handleChange={this.handleChange.bind(this)} maxLength={100} placeholder='placeholderContactName' submitClicked={submitClicked} />
					<TextInputSection sectionName='title' value={title} handleChange={this.handleChange.bind(this)} maxLength={100} placeholder='placeholderContactTitle' submitClicked={submitClicked} />
					<TextInputSection sectionName='phone' value={phone} handleChange={this.handleChange.bind(this)} maxLength={100} placeholder='placeholderContactPhone' submitClicked={submitClicked} />
					<TextInputSection sectionName='personEmail' helperText='emailFormatHelperText' value={email} handleChange={this.handleChange.bind(this)} maxLength={200} placeholder='placeholderContactEmail' submitClicked={submitClicked} />
					<TextInputSection sectionName='link' value={link} handleChange={this.handleChange.bind(this)} maxLength={1000} placeholder='placeholderContactLink' optional submitClicked={submitClicked} />
					<ImageUploadSection sectionName='photo' value={photo} preview={this.state.photo} helperText='photoHelperText' handleChange={this.handleFileChange.bind(this)} optional remove={this.removePhoto.bind(this)} />

				</CardBody>
			</Card>
		)
	}

}

export default ContactInput;