import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Container} from 'reactstrap';
import {Link} from 'react-router-dom';

class ChooseExpert extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Container fluid className='text-center'>
				<div className='limit-width-and-center py-5 px-2'>
					<h2 className='text-uppercase'>
						<FormattedMessage id='chooseExpertHeader' />
					</h2>
					<p>
						<FormattedMessage id='chooseExpertText' />
					</p>
					<Link to='/howto' className='btn btn-primary'>
						<FormattedMessage id='chooseExpertButton' />
					</Link>
				</div>
			</Container>
		)
	}

}

export default ChooseExpert;
