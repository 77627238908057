import React from 'react';
import {Button, Col, FormGroup, FormText, Input, Label, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';

import TextInputSection from '../TextInputSection';
import ImageUploadSection from '../ImageUploadSection';
import SelectSection from '../SelectSection';

import validator from 'validator';
import HttpRequestUtil from '../../util/HttpRequestUtil';
import {RouterHOC} from "../RouterHOC";

class BasicInformationSection extends React.Component {
	constructor(props) {
		super(props);

		var email = this.props.user ? this.props.user.email : '';
		this.state = { editingEmail: false, email: email, loading: false };
	}

	UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
		var email = nextProps.user ? nextProps.user.email : '';
		this.setState({ email: email, loading: false });
	}

	onEditClick(e) {
		e.preventDefault();
		this.setState({ editingEmail: true });
	}

	onCancelClick(e) {
		e.preventDefault();
		this.setState({ editingEmail: false, email: this.props.user.email });
	}

	onEmailChange(e) {
		this.setState({ email: e.target.value });
	}

	updateSuccess() {
		this.props.navigate('/editcomplete');
	}

	onEmailUpdateClick(e) {
		e.preventDefault();
		this.setState({ loading: true });

		var newUser = this.props.user;
		newUser.email = this.state.email;
		var data = JSON.stringify(newUser);

		//TODO set and show some kind of error text on failure?
		HttpRequestUtil.post('/api/user', data, this.updateSuccess.bind(this), () => { });
	}

	render() {
		const {
			company,
			logoUrl,
			handleChange,
			handleSelectChange,
			handleFileChange,
			countries,
			removeLogo,
			submitClicked
		} = this.props;

		return (
			<div name='basicInfoSection' className='bottom-margin'>
				<h5> <FormattedMessage id='basicInfo' /> </h5>

				<FormGroup>
					<Row className='limit-max-width-to-parent'>
						<Col sm={{ size: 'auto', offset: 0 }}>
							<Label for='email' className='right-margin' size='sm' className='input-label'>
								<FormattedMessage id='email' />
							</Label>
						</Col>
						<FormText color='muted' className='caps'> <FormattedMessage id='hiddenOnPublic' /> </FormText>
					</Row>
					<FormText>
						<FormattedMessage id='emailHelperText' />
					</FormText>
					{this.state.editingEmail

						? <Row className='limit-max-width-to-parent py-1'>
							<Col>
								<Input value={this.state.email} onInput={this.onEmailChange.bind(this)} />
							</Col>
							<Col>
								<Button onClick={this.onEmailUpdateClick.bind(this)} color='primary' disabled={!validator.isEmail(this.state.email) || this.state.loading}>
									{this.state.loading ? <FormattedMessage id='loading' /> : <FormattedMessage id='update' />}
								</Button>
								<Button onClick={this.onCancelClick.bind(this)} className='ml-1' disabled={this.state.loading}>
									<FormattedMessage id='cancel' />
								</Button>
							</Col>
						</Row>

						: <Row className='limit-max-width-to-parent py-1'>
							<Col>
								<FormText color='muted' className='font-weight-bold pt-2'>
									{this.state.email}
								</FormText>
							</Col>
							<Col>
								<Button onClick={this.onEditClick.bind(this)}>
									<FormattedMessage id='editEmail' />
								</Button>
							</Col>
						</Row>
					}
				</FormGroup>

				<TextInputSection sectionName='businessId' helperText='businessIdHelperText' submitClicked={submitClicked} value={company.businessId} hiddenOnPublic={true} handleChange={handleChange.bind(this)} maxLength={100} placeholder='placeholderID' country={company.country} />
				<TextInputSection sectionName='name' value={company.name} submitClicked={submitClicked} handleChange={handleChange.bind(this)} maxLength={200} placeholder='placeholderCompanyName' />
				<SelectSection sectionName='country' value={company.country} submitClicked={submitClicked} handleChange={handleSelectChange.bind(this)} options={countries} multiple={false} placeholder='placeholderCountry' />
				<TextInputSection sectionName='city' value={company.city} submitClicked={submitClicked} handleChange={handleChange.bind(this)} maxLength={40} placeholder='placeholderCity' />
				<TextInputSection sectionName='description' area={'textarea'} helperText='descriptionHelperText' value={company.description} handleChange={handleChange.bind(this)} maxLength={1500} placeholder='placeholderDescription' submitClicked={submitClicked} />
				<ImageUploadSection sectionName='logo' value={company.logo} preview={logoUrl} helperText='logoHelperText' handleChange={handleFileChange.bind(this)} optional remove={removeLogo.bind(this)} />
			</div>
		);
	}

}

export default RouterHOC(BasicInformationSection);