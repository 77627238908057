import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Button, Col, Row} from 'reactstrap';
import HttpRequestUtil from './../util/HttpRequestUtil';
import {RouterHOC} from "./RouterHOC";


class RegisterCompany extends React.Component {

    constructor(props) {
        super(props);
        this.state = { uid: this.props.params.id }
    }

    componentDidMount() {
        this.getUser(this.props.params.id);
        if (localStorage.getItem('lng') === 'en') {
            document.title = 'Registration'
        } else { document.title = "Rekisteröityminen" }
    }

    getUser(uid) {
        HttpRequestUtil.get('./api/user/' + uid, this.setUser.bind(this), this.redirectToEmailForm.bind(this));
    }

    setUser(data) {
        var user = data;
        if (!user) {
            this.redirectToEmailForm();
        } else {
            this.setState({ user: user });
            var companyUid = user.companyUid;
            if (companyUid) {
                this.getExistingCompany(companyUid);
            }
        }
    }
    getExistingCompany(uid) {
        HttpRequestUtil.get('./api/company/' + uid, this.setCompany.bind(this), this.setError.bind(this));
    }

    setCompany(data) {
        var company = data[0];
        if (company.inno) {
            this.props.navigate('/registerInno/' + this.state.uid);
        } else {
            this.props.navigate('/registerES/' + this.state.uid);
        }
    }

    setError() {
        this.setState({ loading: false });
    }

    redirectToEmailForm() {
        this.props.navigate('/confirm');
    }

    handleInnoClick() {
        this.props.navigate('/registerInno/' + this.state.uid);
    }

    handleESClick() {
        this.props.navigate('/registerES/' + this.state.uid);
    }

    render() {
        return (
            <div>
                <div className='text-center px-2 mt-4 limit-width-and-center'>
                    <div name='form-header' className='bottom-margin'>
                        <h1 className='bottom-margin-small'> <FormattedMessage id='registerYourCompany' /> </h1>
                        <div className='text-start'> <FormattedMessage id='formIntro' /> </div>
                    </div>
                    <div className='text-start bottom-margin-small'><FormattedMessage id="completeInEng" /></div>
                    <Row>
                        <Col style={{ paddingBottom: '5px' }}>
                            <Button className='regButton' onClick={this.handleESClick.bind(this)}><div className='regButtonText showEnter'>
                                <FormattedMessage id='ESButton' /> </div>
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

}
export default RouterHOC(RegisterCompany);