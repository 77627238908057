import React from 'react';
import {FormattedMessage} from 'react-intl';
import darkBlueStar from '../../images/star-icon-darkblue.svg';
import greyStar from '../../images/star-icon-grey.svg';
import halfStar from '../../images/star-icon-half.svg';
import _ from 'lodash';
import FormattedMessageHOC from "./FormattedMessageHOC";

class StarRating extends React.Component {
    constructor(props) {
        super(props);

    }

    getStarRating(stars, ratings) {
        var starArray, halfStart, halfEnd, completeArray;
        var emptyArray = _.fill(Array(stars), 'empty');
        var roundedNumber = Math.round(ratings);

        if (roundedNumber > ratings) {
            starArray = _.fill(emptyArray, 'star', 0, ratings);
            halfStart = roundedNumber - 1;
            completeArray = _.fill(starArray, 'half', halfStart, roundedNumber);
        } else if (roundedNumber < ratings) {
            starArray = _.fill(emptyArray, 'star', 0, roundedNumber);
            halfEnd = roundedNumber + 1;
            completeArray = _.fill(starArray, 'half', roundedNumber, halfEnd);
        }

        var starArray = _.fill(emptyArray, 'star', 0, ratings);

        return starArray;

    }

    render() {
        var {
            ratings,
            stars,
            className,
            text
        } = this.props;


        var starArray = this.getStarRating(stars, ratings)

        return (
            <div className={className ? className : ''}>
                <div>{text != null ? <span className="bold" style={{ marginLeft: '5px' }}><FormattedMessageHOC {...{id: text}} /></span> : null}</div>
                {starArray.map((value, i) => {
                    return (<span className='starColumn' key={i} >
                        {(value === 'star') ? <img src={darkBlueStar} style={{ width: '1.5em' }} /> : (value === 'half') ? <img src={halfStar} style={{ width: '1.5em' }} /> : <img src={greyStar} style={{ width: '1.5em' }} />}
                    </span>)
                })}
            </div>
        );


    }
}

export default StarRating;