import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Label, Row} from 'reactstrap';

import TextInputSection from '../TextInputSection';
import SelectSection from '../SelectSection';

class ServicesSection extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {
			user,
			company,
			handleChange,
			handleSelectChangeLimited,
			countries,
			industries,
			services,
			submitClicked,
			industriesHelper,
			servicesHelper,
			countriesHelper
		} = this.props;


		return (
			<div name='servicesSection' className='bottom-margin'>
				<h5> <FormattedMessage id='servicesHeader' /> </h5>
				{(user && user.email.indexOf('@businessfinland.fi') > -1)
					? <span>
						<Row className='limit-max-width-to-parent' size='sm'>
							<Label for='services' sm='7' size='sm' className='input-label'> <FormattedMessage id='services' /> </Label>
						</Row>
						<Row className='limit-max-width-to-parent ps-4 text-muted' size='sm'>
							<FormattedMessage name='services' id='finpro' />
						</Row>
					</span>
					: null
				}

				{(user && user.email.indexOf('@formin.fi') > -1)
					? <span>
						<Row className='limit-max-width-to-parent' size='sm'>
							<Label for='services' sm='7' size='sm' className='input-label'> <FormattedMessage id='services' /> </Label>
						</Row>
						<Row className='limit-max-width-to-parent ps-4 text-muted' size='sm'>
							<FormattedMessage name='services' id='embassy' />
						</Row>
					</span>
					: null
				}

				{(user && (user.email.indexOf('@businessfinland.fi') === -1) && (user.email.indexOf('@formin.fi') === -1))
					? <span>
						<SelectSection sectionName='services' value={company.services} handleChange={handleSelectChangeLimited.bind(this)} options={services} multiple={true} placeholder='placeholderServices' submitClicked={submitClicked} limited />
						<TextInputSection sectionName='descriptionServices' area={'textarea'} value={company.descriptionServices} handleChange={handleChange.bind(this)} maxLength={1500} placeholder='placeholderDescriptionServices' submitClicked={submitClicked} helper={servicesHelper} className='description-area'/>
					</span>
					: null
				}


				<SelectSection sectionName='industries' value={company.industries} handleChange={handleSelectChangeLimited.bind(this)} options={industries} multiple={true} placeholder='placeholderIndustries' submitClicked={submitClicked} limited />
				<TextInputSection sectionName='descriptionIndustries' area={'textarea'} value={company.descriptionIndustries} handleChange={handleChange.bind(this)} maxLength={1500} placeholder='placeholderDescriptionIndustries' submitClicked={submitClicked} helper={industriesHelper} className='description-area'/>

				<SelectSection sectionName='countries' helperText='targetCountryHelperText' value={company.countries} handleChange={handleSelectChangeLimited.bind(this)} options={countries} multiple={true} placeholder='placeholderCountries' submitClicked={submitClicked} limited />
				<TextInputSection sectionName='descriptionCountries' area={'textarea'} value={company.descriptionCountries} handleChange={handleChange.bind(this)} maxLength={1500} placeholder='placeholderDescriptionCountries' submitClicked={submitClicked} helper={countriesHelper} className='description-area'/>
				<TextInputSection sectionName='serviceLanguage' value={company.serviceLanguage} handleChange={handleChange.bind(this)} maxLength={100} placeholder='placeholderLanguages' submitClicked={submitClicked} />
			</div>
		);
	}

}

export default ServicesSection;