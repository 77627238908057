import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Label, Row} from 'reactstrap';

import TextInputSection from '../TextInputSection';
import SelectSection from '../SelectSection.jsx';

class ServicesSection extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {
			user,
			company,
			handleChange,
			handleSelectChangeLimited,
			services,
			submitClicked,
			servicesHelper,
		} = this.props;

		return (
			<div name='servicesSection' className='bottom-margin'>
				<h5> <FormattedMessage id='servicesHeader' /> </h5>
				{(user && user.email.indexOf('@businessfinland.fi') > -1)
					? <span>
						<Row className='limit-max-width-to-parent' size='sm'>
							<Label for='services' sm='7' size='sm' className='input-label'> <FormattedMessage id='services' /> </Label>
						</Row>
						<Row className='limit-max-width-to-parent ps-4 text-muted' size='sm'>
							<FormattedMessage name='services' id='finpro' />
						</Row>
					</span>
					: null
				}

				{(user && user.email.indexOf('@formin.fi') > -1)
					? <span>
						<Row className='limit-max-width-to-parent' size='sm'>
							<Label for='services' sm='7' size='sm' className='input-label'> <FormattedMessage id='services' /> </Label>
						</Row>
						<Row className='limit-max-width-to-parent ps-4 text-muted' size='sm'>
							<FormattedMessage name='services' id='embassy' />
						</Row>
					</span>
					: null
				}

				{(user && (user.email.indexOf('@businessfinland.fi') === -1) && (user.email.indexOf('@formin.fi') === -1))
					? <span>
						<SelectSection sectionName='services' value={company.services} handleChange={handleSelectChangeLimited.bind(this)} options={services} multiple={true} placeholder='placeholderServices' submitClicked={submitClicked} limited />
						<TextInputSection sectionName='descriptionServices' area={'textarea'} value={company.descriptionServices} handleChange={handleChange.bind(this)} maxLength={1500} placeholder='placeholderDescriptionServicesInno' submitClicked={submitClicked} helper={servicesHelper} className='description-area' />
					</span>
					: null
				}
			</div>
		);
	}

}

export default ServicesSection;