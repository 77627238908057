import React from 'react';
import {Button, Col, Form, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import Recaptcha from 'react-recaptcha';
import HttpRequestUtil from '../../util/HttpRequestUtil';

import TextInputSection from '../TextInputSection';

import validator from 'validator';

const emptyRequest = {
  contactName: null,
  companyName: null,
  contactEmail: null,
  contactPhone: '',
  message:
    'Dear ,\n\rOur company is in the ... business and specializes on ...\n\r' +
    "We are looking for an expert to help us to enter/expand in ... market.\n\rThe key issue we'd like to solve is ..." +
    'Can you please contact us by <date> using the email address provided.',
};

class ContactRequestSection extends React.Component {
  constructor(props) {
    super(props);

    var initialRequest = Object.assign({}, emptyRequest);

    this.state = {
      request: initialRequest,
      sendError: false,
      reCaptchaState: null,
      sendSuccess: false,
    };
  }

  sendContactRequest(e) {
    e.preventDefault();
    this.setState({sendSuccess: false});
    this.setState({sendError: false});

    var companyData = this.props.company;
    var requestData = this.state.request;

    requestData.captcha = this.state.reCaptchaState;

    var jsonData = JSON.stringify(requestData);

    //TODO set and show some kind of error text on failure?
    HttpRequestUtil.post(
      './api/contact/' + companyData.uid,
      jsonData,
      this.sendSuccess.bind(this),
      this.setSendError.bind(this)
    );
  }

  sendSuccess() {
    this.setState({sendSuccess: true});
  }

  setSendError() {
    this.setState({sendError: true});
  }

  handleRequestChange(key, e) {
    e.preventDefault();
    var request = this.state.request;
    request[key] = e.target.value;
    this.setState({request: request});
  }

  checkData(request) {
    if (request) {
      if (
        request.contactName &&
        validator.isEmail('' + request.contactEmail) &&
        request.companyName &&
        request.message
      ) {
        return true;
      }
    }

    return false;
  }

  verifyReCaptcha(data) {
    this.setState({reCaptchaState: data});
  }

  render() {
    const {company} = this.props;

    var {sendError, sendSuccess, request, reCaptchaState} = this.state;

    var valid = this.checkData(request);

    return (
      <div className="text-center px-2 limit-width-and-center">
        <h1 className="bottom-margin-small caps">
          <FormattedMessage id="leaveContactRequest" />
        </h1>
        <Form className="text-start">
          <Row>
            <Col>
              <TextInputSection
                sectionName="contactName"
                value={request.contactName}
                handleChange={this.handleRequestChange.bind(this)}
                maxLength={100}
                placeholder="placeholderName"
              />
              <TextInputSection
                sectionName="companyName"
                value={request.companyName}
                handleChange={this.handleRequestChange.bind(this)}
                maxLength={100}
                placeholder="placeholderCompanyName"
              />
              <TextInputSection
                sectionName="contactEmail"
                value={request.contactEmail}
                handleChange={this.handleRequestChange.bind(this)}
                maxLength={200}
                placeholder="placeholderContactEmail"
              />
              <TextInputSection
                sectionName="contactPhone"
                optional
                value={request.contactPhone}
                handleChange={this.handleRequestChange.bind(this)}
                maxLength={100}
                placeholder="placeholderContactPhone"
              />
            </Col>
            <Col>
              <TextInputSection
                area
                className="message-input"
                sectionName="message"
                value={request.message}
                handleChange={this.handleRequestChange.bind(this)}
                maxLength={500}
                placeholder="placeholderMessage"
              />
              <Recaptcha
                sitekey="6LfMMm0aAAAAANmUmNjQHPxZKSmuMXz2LA8NGCPv"
                render="explicit"
                verifyCallback={this.verifyReCaptcha.bind(this)}
                onloadCallback={() => {}}
              />
              <Button
                block
                color="primary"
                className="mt-2"
                disabled={!valid || !reCaptchaState}
                onClick={this.sendContactRequest.bind(this)}
              >
                <FormattedMessage id="submitContactRequest" />
              </Button>
              {sendSuccess ? (
                <span className="text-success">
                  <FormattedMessage id="contactRequestSent" />
                </span>
              ) : null}
              {sendError ? (
                <span className="text-danger">
                  <FormattedMessage id="errorSendingInformation" />
                </span>
              ) : null}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default ContactRequestSection;
