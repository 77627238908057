import React from 'react';
import {Button, Col, Form, FormGroup, InputGroup, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import TextInputSection from './TextInputSection';
import HttpRequestUtil from '../util/HttpRequestUtil';

import validator from 'validator';
import {RouterHOC} from "./RouterHOC";

class EmailForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			loading: false,
			error: null
		};
	}

	componentDidMount() {
		if (localStorage.getItem('lng') === 'en') {
			document.title = 'Registration'
		} else { document.title = "Rekisteröityminen" }
	}

	checkEmail(email) {
		return validator.isEmail('' + email);
	}

	handleChange(sectionName, e) {
		this.setState({ email: e.target.value });
	}

	handleSubmit(e) {
		e.preventDefault();

		this.setState({ loading: true });
		var email = this.state.email;
		var userJson = JSON.stringify({ email: email });
		HttpRequestUtil.post('/api/user', userJson, this.redirectToThanks.bind(this), this.showError.bind(this));
	}

	redirectToThanks() {
		this.setState({ loading: false, error: null });
		this.props.navigate('/emailcomplete');
	}

	showError() {
		//TODO show error text somewhere? more errors than the one?
		this.setState({ loading: false, error: 'generalError' });
	}

	render() {

		var valid = this.checkEmail(this.state.email);

		return (
			<div className='text-center px-2 mt-4 limit-width-and-center'>

				<div name='form-header' className='bottom-margin'>
					<h1 className='bottom-margin-small'> <FormattedMessage id='registerYourCompany' /> </h1>
					<div className='text-start'> <FormattedMessage id='formIntro' /> </div>
				</div>

				<div className='text-start jumbotron-1'>
					<Form>
						<h2 className='bottom-margin-small'> <FormattedMessage id='confirmEmailBoxHeader' /> </h2>
						<p><FormattedMessage id='confirmEmailBoxText' /></p>
						<InputGroup>
							<Col sm='7'>
								<TextInputSection sectionName='email' value={this.state.email} handleChange={this.handleChange.bind(this)} maxLength={1000} placeholder='placeholderContactEmail' />
							</Col>
							<Col sm='4' className='top-margin bottom-margin-small ms-4'>
								<Button block color='primary' disabled={!valid || this.state.loading} onClick={this.handleSubmit.bind(this)} >
									{this.state.loading
										? <FormattedMessage id='loading' />
										: <FormattedMessage id='submit' />
									}
								</Button>
							</Col>
						</InputGroup>
					</Form>
				</div>

			</div>
		);
	}

}

export default RouterHOC(EmailForm);